import React, { useEffect, useState } from "react";
import Button from "react-bootstrap/Button";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import { BiErrorCircle } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { axiosOpen } from "../../api/axios";
import logo from "../../assests/images/logo.png";
import { toast } from "react-toastify";
import secureLocalStorage from "react-secure-storage";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";

const LOGIN_URL = "auth/login";

const LoginForm = () => {
  const navigate = useNavigate();

  const [showToster, setShowToster] = useState(false);
  const [visible, setVisible] = useState(false);
  const [rememberMe, setRememberMe] = useState(false);

  // Define Yup validation schema
  const validationSchema = Yup.object().shape({
    email: Yup.string().email("Invalid email").required("Email is required"),
    password: Yup.string()
      .min(6, "Password must be at least 6 characters")
      .required("Password is required"),
  });

  // Initial form values
  const initialValues = {
    email: "",
    password: "",
  };

  const handleSubmit = async (values, { setSubmitting }) => {
    const requestPayload = {
      ...values,
    };

    if (rememberMe) {
      localStorage.setItem("email", values.email);
      secureLocalStorage.setItem("password", values.password);
    }

    try {
      const response = await axiosOpen.post(LOGIN_URL, requestPayload);
      const userDetails = {
        user: response?.data?.data,
        token: response?.data?.token,
      };
      localStorage.userDetails = JSON.stringify(userDetails);
      navigate("/", { replace: true });
    } catch (err) {
      toast.error(err?.response?.data?.message);
    } finally {
      setSubmitting(false);
    }
  };

  const handleRememberMe = (e) => {
    if (!e.target.checked) {
      localStorage.removeItem("email");
      localStorage.removeItem("password");
    }
    setRememberMe(e.target.checked);
  };

  useEffect(() => {
    const savedEmail = localStorage.getItem("email");
    const savedPassword = secureLocalStorage.getItem("password");

    if (savedEmail && savedPassword) {
      initialValues.email = savedEmail;
      initialValues.password = savedPassword;
      setRememberMe(true);
    }
  }, []);

  return (
    <>
      <div
        aria-live="polite"
        aria-atomic="true"
        className="position-relative login-page"
      >
        {showToster && (
          <ToastContainer position="top-end" className="p-3">
            <Toast>
              <Toast.Header className="toaster-header">
                <BiErrorCircle className="toaster-icon" />
                <div className="toaster-body">
                  <strong className="me-auto">Oops!</strong>
                  <br />
                  <strong className="me-auto">Invalid Credential</strong>
                  {/* <small className="text-muted">just now</small> */}
                </div>
              </Toast.Header>
            </Toast>
          </ToastContainer>
        )}
        {/* <div className="left-section">
      </div> */}
        <div className="right-section">
          <div className="form-signin">
            <div>
              <img alt="brand logo" src={logo} style={{ height: "50px" }} />
            </div>
            <h1 style={{ color: "#111" }}>LOGIN</h1>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form className="form">
                  <div className="form-group mb-3 emailField">
                    <Field
                      placeholder="Email"
                      type="email"
                      className={`form-control  ${
                        errors.email && touched.email ? "is-invalid" : ""
                      }`}
                      id="email"
                      name="email"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div
                    className="form-group mb-3"
                    style={{ position: "relative" }}
                  >
                    <Field
                      placeholder="Enter password"
                      type={visible ? "text" : "password"}
                      className={`form-control ${
                        errors.password && touched.password ? "is-invalid" : ""
                      }`}
                      id="password"
                      name="password"
                    />

                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-danger"
                    />
                    {visible ? (
                      <AiOutlineEye
                        size={25}
                        onClick={() => setVisible(false)}
                        className="visibleIcon"
                      />
                    ) : (
                      <AiOutlineEyeInvisible
                        size={25}
                        onClick={() => setVisible(true)}
                        className="visibleIcon"
                      />
                    )}
                  </div>

                  <div className="col-12 d-flex justify-content-between mb-2">
                    <label className="my-checkbox d-flex align-items-center">
                      <input
                        type="checkbox"
                        checked={rememberMe}
                        onChange={handleRememberMe}
                      />
                      Remember me
                    </label>
                    <Link
                      to="/forget-password"
                      style={{ color: "#111", textAlign: "center" }}
                    >
                      {" "}
                      Forget Password{" "}
                    </Link>
                  </div>

                  <Button variant="primary" className="login-btn" type="submit">
                    Login
                  </Button>
                  <Button
                    variant="link"
                    className="text-decoration-none text-black"
                    onClick={() => {
                      navigate("/register");
                    }}
                  >
                    Don't have account ? <span> Register Here </span>
                  </Button>
                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>

      <footer
        style={{
          textAlign: "center",
          padding: "1rem",
          backgroundColor: "#fff",
        }}
      >
        © {new Date().getFullYear()} Crafted Meals, powered by{" "}
        <span style={{ color: "#ef233c" }}>❤</span>{" "}
        <Link
          to="https://buildyourai.consulting/"
          target="_blank"
          rel="noopener noreferrer"
          className="dark-green"
        >
          Build Your AI Consulting{" "}
        </Link>
      </footer>
    </>
  );
};

export default LoginForm;

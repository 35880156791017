// @flow strict

import * as React from 'react';
import { toast } from 'react-toastify';
import { axiosSecure, getAuthorizationHeader } from '../../api/axios';
import SavedPostUI from '../../component/SavedPost/SavedPostUI';

function SavedPost() {
  const [totalClientCount, setTotalClientCount] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [savedPost, setSavedPost] = React.useState([])


  const handlePagination = async (page) => {
    setCurrentPage(page);
  };

  React.useEffect(() => {
    const controller = new AbortController();
    const fetchSavedPost = async () => {
      try {
        // setDataLoader(true);
        const response = await axiosSecure.get(
          `trainer/social-media-posts?page=${currentPage}&limit=10`,
          {
            headers: { Authorization: getAuthorizationHeader() },
          }
        );
        setSavedPost(response?.data?.data);
        setCurrentPage(Number(response?.data.paginationData?.currentPage));
        setTotalClientCount(Number(response?.data.paginationData?.totalCount));
      } catch (err) {
        toast.error(err.response.data.message);
      }
    };
    fetchSavedPost();

    return () => controller && controller.abort();
  }, [currentPage]);

  return (
    <>
      <SavedPostUI
        savedPost={savedPost}
        handlePagination={handlePagination}
        totalClientCount={totalClientCount}
        currentPage={currentPage}
      />
    </>
  );
};

export default SavedPost;
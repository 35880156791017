
import React, { useState } from 'react'
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { BiErrorCircle } from "react-icons/bi";
import { AiOutlineEye, AiOutlineEyeInvisible } from "react-icons/ai";
import { Link, useNavigate } from 'react-router-dom';
import logo from "../../assests/images/logo.png";
// import { Toast, ToastContainer, Button } from "react-bootstrap";
import { Button, Toast, ToastContainer } from 'react-bootstrap';
import { toast } from "react-toastify";
import { axiosOpen } from "../../api/axios";

const REGISTER_URL = "auth/register";

const validationSchema = Yup.object().shape({
  firstName: Yup.string()
    .max(35, "First name must be at most 35 characters")
    .required("First name is required"),
  lastName: Yup.string()
    .max(35, "Last name must be at most 35 characters")
    .required("Last name is required"),
  email: Yup.string().email("Invalid email").required("Email is required"),

  phoneNumber: Yup.string().matches(
    /^\+?[0-9]{1,3}-?[0-9]{6,14}$/g,
    'Please enter a valid phone number'
  ),
  organizationName: Yup.string().required("Organization Name is required"),
  password: Yup.string()
    .min(8, "Password must be at least 8 characters")
    .required("Password is required"),
});

// Initial form values
const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  organizationName: "",
  phoneNumber: "",
  password: "",
};

const RegisterForm = () => {
  const navigate = useNavigate();
  const [visible, setVisible] = useState(false);
  const [showToster, setShowToster] = useState(false);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {

    const requestPayload = {
      ...values
    }

    try {
      const response = await axiosOpen.post(REGISTER_URL, requestPayload);
      toast.success(response?.data?.message);
      // navigate("/", { replace: true });

    } catch (err) {
      toast.error(err?.response?.data?.message);
    } finally {
      setSubmitting(false)
    }


    // Clear Input Field;
    resetForm();
  };

  return (
    <>
      <div className="position-relative login-page">
        {showToster && (
          <ToastContainer position="top-end" className="p-3">
            <Toast
              onClose={() => setShowToster(false)}
              show={showToster}
              delay={3000}
              autohide
            >
              <Toast.Header className="toaster-header">
                <BiErrorCircle className="toaster-icon" />
                <div className="toaster-body">
                  <strong className="me-auto">Oops!</strong>
                  <br />

                  <strong className="me-auto">Email or Username already in Use!</strong>
                </div>
              </Toast.Header>
            </Toast>
          </ToastContainer>
        )}

        <div className="right-section">
          <div className='form-signin'>
            <div className='logoImg'>
              <img alt="brand logo" src={logo} style={{ height: "50px" }} />
            </div>
            <h1 style={{ color: "#111" }}>Register Form</h1>
            <Formik
              initialValues={initialValues}
              validationSchema={validationSchema}
              onSubmit={handleSubmit}
            >
              {({ values, errors, touched, isSubmitting }) => (
                <Form className="form">
                  <div className="form-group mb-3">
                    <Field
                      type="text"
                      placeholder="First Name"
                      className={`form-control ${errors.firstName && touched.firstName
                        ? "is-invalid"
                        : ""
                        }`}
                      id="firstName"
                      name="firstName"
                      value={values.firstName.slice(0, 35)}
                    />
                    <ErrorMessage
                      name="firstName"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="form-group mb-3">
                    <Field
                      placeholder="Last Name"
                      type="text"
                      className={`form-control ${errors.lastName && touched.lastName
                        ? "is-invalid"
                        : ""
                        }`}
                      id="lastName"
                      name="lastName"
                      value={values.lastName.slice(0, 35)}
                    />
                    <ErrorMessage
                      name="lastName"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="form-group mb-3 emailField">
                    <Field
                      placeholder="Email"
                      type="email"
                      className={`form-control  ${errors.email && touched.email ? "is-invalid" : ""
                        }`}
                      id="email"
                      name="email"
                    />
                    <ErrorMessage
                      name="email"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div className="form-group mb-3">
                    <Field
                      placeholder="Phone Number"
                      type="text"
                      className={`form-control ${errors.phoneNumber && touched.phoneNumber
                        ? "is-invalid"
                        : ""
                        }`}
                      id="phoneNumber"
                      name="phoneNumber"
                    />
                    <ErrorMessage
                      name="phoneNumber"
                      component="div"
                      className="text-danger"
                    />
                  </div>



                  <div className="form-group mb-3">
                    <Field
                      placeholder="Organization Name"
                      type="text"
                      className={`form-control ${errors.organizationName && touched.organizationName ? "is-invalid" : ""
                        }`}
                      id="organizationName"
                      name="organizationName"
                    />
                    <ErrorMessage
                      name="organizationName"
                      component="div"
                      className="text-danger"
                    />
                  </div>

                  <div
                    className="form-group mb-3"
                    style={{ position: "relative" }}
                  >
                    <Field
                      placeholder="Enter password"
                      type={visible ? "text" : "password"}
                      className={`form-control ${errors.password && touched.password
                        ? "is-invalid"
                        : ""
                        }`}
                      id="password"
                      name="password"
                    />

                    <ErrorMessage
                      name="password"
                      component="div"
                      className="text-danger"
                    />
                    {visible ? (
                      <AiOutlineEye
                        size={25}
                        onClick={() => setVisible(false)}
                        className="visibleIcon"
                      />
                    ) : (
                      <AiOutlineEyeInvisible
                        size={25}
                        onClick={() => setVisible(true)}
                        className="visibleIcon"
                      />
                    )}
                  </div>
                  <Button variant="primary" className="login-btn" type="submit">
                    Register
                  </Button> <br />
                  <Button
                    variant="link"
                    className="text-decoration-none text-black"
                    onClick={() => { navigate("/login") }}
                  >
                    Already have an Account? <span>Login</span>
                  </Button>

                </Form>
              )}
            </Formik>
          </div>
        </div>
      </div>

      <div>
        <section style={{ textAlign: "center", padding: "1rem", backgroundColor: "#fff" }}>
          © {new Date().getFullYear()} Crafted Meals, powered by{" "}
          <span style={{ color: "#ef233c" }}>❤</span>{" "}
          <Link
            to="https://buildyourai.consulting/"
            target="_blank"
            rel="noopener noreferrer"
            className="dark-green"
          >
            Build Your AI Consulting{" "}
          </Link>
        </section>
      </div>
    </>
  )
}

export default RegisterForm;
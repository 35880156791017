import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosSecure, getAuthorizationHeader } from "../../../api/axios";
import "./Edit.scss";
import EditUserForm from "./edit-form";

const EditUserPlan = () => {
  const { planId } = useParams();
  const [initialValues, setInitialValues] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleEditPlan = async (values, { setSubmitting }) => {

    const requestPayload = JSON.stringify({
      numberOfClient: values.numberOfClient,
      numberOfSocialMediaIdea: values.numberOfSocialMediaIdea,
      numberOfmealPlan: values.numberOfmealPlan,
      planName: values.planName,
      price: values.price,
      stripePriceKey: parseInt(values.price) !== 0 ? values.stripePriceKey : "N/A",
      userPlanId: initialValues._id,
    })

    try {
      const response = await axiosSecure.post(
        "/admin/user-plan/add",
        requestPayload,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );
      toast.success(response?.data?.message);
      navigate("/user-plan", { replace: true });
    } catch (err) {
      toast.error(err?.response?.data?.message);
    } finally {
      setSubmitting(false);
    }
  }

  // GET User
  useEffect(() => {
    const controller = new AbortController();
    const fetchUserData = async () => {
      setLoading(true);
      try {
        const response = await axiosSecure.get(`/user-plan/${planId}`, {
          headers: { Authorization: getAuthorizationHeader() },
        });
        setInitialValues(response?.data?.data);
      } catch (err) {
        toast.error(err?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    };
    fetchUserData();

    return () => controller && controller.abort();
  }, [planId]);

  if (loading) {
    return (
      <Container>
        <Row>
          <Col>
            <div className="user_card_wrapper m-auto">
              <div className="d-flex justify-content-center align-items-center h-100">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <div className="flex-grow-1">
      {initialValues && initialValues?._id ? (
        <EditUserForm
          defaultValue={initialValues}
          handleEditPlan={handleEditPlan}
        />
      ) : null}
    </div>
  );
};

export default EditUserPlan;

import React, { useEffect, useState } from 'react'
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import { axiosOpen } from '../../api/axios';
import { toast } from 'react-toastify';
const EMAIL_VERIFY_URL = "auth/email-verify";

const VerifyEmail = () => {
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    // Access query parameters
    const query = Object.fromEntries(searchParams);

    useEffect(() => {
        if (query?.emailVerifyToken) {
            const emailVerify = async () => {
                try {
                    const response = await axiosOpen.put(EMAIL_VERIFY_URL, { eToken: query.emailVerifyToken });
                    navigate('/login');
                } catch (error) {
                    toast.error(error.response?.data?.message);
                }
            };
            emailVerify();
        }
    }, [query, navigate]);

    return (
        <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100%", width: "100%" }}>
            <h4>Verification Email Successfully...</h4>
        </div>
    )
}

export default VerifyEmail;

// @flow strict

import * as React from 'react';
import { Card, Container } from 'react-bootstrap';
import PaginationComponent from '../Pagination/Pagination';
import SavedMealCard from './SavedMealCard';


function SavedMealsUI({
  savedMeals,
  totalMealsCount,
  currentPage,
  handlePagination
}) {
  return (
    <Container className="d-flex justify-content-center my-4 social-media-section">
      <Card className="social-media-idea-generator shadow border-0 rounded">
        <div
          className="d-flex p-3 border-bottom justify-content-between 
          align-items-center social-media-header">
          <>
            <h4 className="">Saved Meal Plans</h4>
          </>
        </div>
        <div className="social-media-idea-generator-body">
          <div>

            {
              savedMeals.length > 0 ?
                savedMeals.map((meal, i) => (
                  <SavedMealCard meal={meal} key={i} />
                ))
                :
                <div className="d-flex mt-5 justify-content-center align-content-center">
                  <h4>You have not saved any meals.</h4>
                </div>
            }
            <div className="d-flex justify-content-center mt-4 ">
              <PaginationComponent
                total={totalMealsCount}
                itemsPerPage={10}
                currentPage={currentPage}
                onPageChange={(page) => handlePagination(page)}
              />
            </div>
          </div>
        </div>
      </Card>
    </Container>
  );
};

export default SavedMealsUI;
// @flow strict

import * as React from 'react';
import { Button, Card, Container } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import PaginationComponent from '../Pagination/Pagination';
import SavedPostCard from './savedPostCard';


function SavedPostUI({
  savedPost,
  handlePagination,
  totalClientCount,
  currentPage
}) {
  const navigate = useNavigate();

  return (
    <Container className="d-flex justify-content-center my-4 social-media-section">
      <Card className="social-media-idea-generator shadow border-0 rounded">
        <div
          className="d-flex p-3 border-bottom justify-content-between 
          align-items-center social-media-header">
          <>
            <h4 className="">Saved Posts</h4>
            <Button
              className="btn btn-rimary"
              onClick={() => navigate('/socialmedia-idea')}
            >Generate New Posts</Button>
          </>
        </div>
        <div className="social-media-idea-generator-body">
          <div>

            {
              savedPost.length > 0 ?
                savedPost.map((post, i) => (
                  <SavedPostCard post={post} key={i} />
                ))
                :
                <div className="d-flex mt-5 justify-content-center align-content-center">
                  <h3>You have not saved any post.</h3>
                </div>
            }
            <div className="d-flex justify-content-center mt-4 ">
              <PaginationComponent
                total={totalClientCount}
                itemsPerPage={10}
                currentPage={currentPage}
                onPageChange={(page) => handlePagination(page)}
              />
            </div>
          </div>
        </div>
      </Card>
    </Container>
  );
};

export default SavedPostUI;
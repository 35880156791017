import React, { useState } from "react";
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.min.css";
import { ForgotPassword, LoginForm, Layout as PrivateRoute, RegisterForm, ResetPassword, VerifyResetPasswordToken } from "./Pages";
import VerifyEmail from "./Pages/VerifyEmail";
import { axiosSecure } from "./api/axios";
import ExpiredModal from "./component/Modal/expiredModal";
import StockProvider from "./contexts/StockContext";
import { isLoggedIn } from "./service";

const RouterGuard = ({ children }) => {
  const location = useLocation();

  return isLoggedIn() ? (
    <PrivateRoute>{children}</PrivateRoute>
  ) : (
    <Navigate to="/login" state={{ from: location }} replace />
  );
};

function App() {
  const [showExpireModal, setShowExpireModal] = useState(false);

  // Interceptor for responses
  axiosSecure.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error.response.status === 403) {
        setShowExpireModal(true);
      }
      return Promise.reject(error);
    }
  );

  return (
    <StockProvider>
      <ToastContainer limit={1} autoClose={3500} />
      <Routes>
        <Route path="login" element={<LoginForm />} />
        <Route path="register" element={<RegisterForm />} />
        <Route path="verify-email" element={<VerifyEmail />} />
        <Route path="forget-password" element={<ForgotPassword />} />
        <Route path="reset-password" element={<ResetPassword />} />
        <Route path="verify-reset-pass-token" element={<VerifyResetPasswordToken />} />
        <Route path="/*" element={<RouterGuard />} />
      </Routes>
      <ExpiredModal show={showExpireModal} setShow={setShowExpireModal} />
    </StockProvider>
  );
}

export default App;

import React from "react";
import { Card, Col, Container, Row } from "react-bootstrap";

const PageNotFound = () => {
  return (
    <Container>
      <Row>
        <Col>
          <div className="user_card_wrapper m-auto">
            <Card>
              <Card.Body>
                <Card.Title className="text-danger text-center">
                  PAGE NOT FOUND
                </Card.Title>
              </Card.Body>
            </Card>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default PageNotFound;

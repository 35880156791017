import React, { useEffect, useState } from "react";
import { axiosSecure, getAuthorizationHeader } from "../../../api/axios";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import OrderUI from "../../../component/SubscriptionHistory/OrderViewUI/OrderUI";

const SubscriptionHistoryView = () => {
    let { orderId } = useParams();
  const [viewOrder, setViewOrder] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const controller = new AbortController();
    const fetchHistoryData = async () => {
      try {
        setLoading(true);
        const response = await axiosSecure.get(`/trainer/order/${orderId}`, {
          headers: { Authorization: getAuthorizationHeader() },
        });
        setViewOrder(response?.data?.data);
      } catch (err) {
        toast.error(err?.response?.data?.message);
      }finally {
        setLoading(false);
      }
    };
    fetchHistoryData();
    return () => controller && controller.abort();
  }, []);

  return <div><OrderUI subscription={viewOrder}/></div>;
};

export default SubscriptionHistoryView;

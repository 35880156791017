import React, { useState } from "react";
import { Card, Container, Table } from "react-bootstrap";
import { convertDate } from "../../Utility/utility";
import PaginationComponent from "../Pagination/Pagination";
import { Link, useNavigate } from "react-router-dom";

const SubscriptionHistoryData = ({
  subscriptions,
  handlePagination,
  currentPage,
  limit,
  totalOrderCount,
}) => {
  const navigate = useNavigate();
  const [dataLoader, setDataLoader] = useState(false);

  return (
    <div>
      <Container className="my-4">
        <Card className="shadow border-0 rounded p-4">
          <div
            className="d-flex p-3 border-bottom justify-content-between
                     align-items-center"
          >
            <h2 className="">Subscription History</h2>
          </div>

          {subscriptions && subscriptions.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th>Plan Name</th>
                  <th>No. Of Client</th>
                  <th>No. Of Plan</th>
                  {/* <th>SubTotal</th> */}
                  <th>Total</th>
                  <th>Activated At</th>
                  <th>ExpiresAt</th>
                  <th>Action</th>
                </tr>
              </thead>
              <tbody>
                {dataLoader ? (
                  <div className="d-flex justify-content-center align-items-center h-100">
                    <div className="spinner-border" role="status">
                      <span className="visually-hidden">Loading...</span>
                    </div>
                  </div>
                ) : (
                  subscriptions.map((subscription) => (
                    <tr>
                      <td>
                        <Link
                          class="text-decoration-none linked-text"
                          to={`/subscription-history/view/${subscription?._id}`}
                        >
                          {" "}
                          {subscription?.plan[0]?.planName}{" "}
                        </Link>
                      </td>
                      <td>{subscription?.plan[0]?.numberOfClient}</td>
                      <td>{subscription?.plan[0]?.numberOfmealPlan}</td>

                      <td>${subscription?.amountTotal / 100} </td>
                      <td>{convertDate(subscription?.activatedAt)}</td>
                      <td>{convertDate(subscription?.expiresAt)}</td>
                      <td>
                        <span
                          onClick={() =>
                            navigate(
                              `/subscription-history/view/${subscription?._id}`
                            )
                          }
                          className="viewHistory"
                        >
                          <i className="bi bi-eye"></i>
                        </span>
                      </td>
                    </tr>
                  ))
                )}
              </tbody>
            </Table>
          ) : (
            <Card>
              <Card.Body>
                <Card.Title className="text-danger text-center">
                  No Subscription History available
                </Card.Title>
              </Card.Body>
            </Card>
          )}

          <div className="d-flex justify-content-end relative bottom-20 me-3 mt-3 ">
            <PaginationComponent
              total={totalOrderCount}
              itemsPerPage={limit}
              currentPage={currentPage}
              onPageChange={(page) => handlePagination(page)}
            />
          </div>
        </Card>
      </Container>
    </div>
  );
};

export default SubscriptionHistoryData;

// @flow strict

import * as React from 'react';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { axiosSecure, getAuthorizationHeader } from '../../api/axios';
import SavedMealsUI from '../../component/SavedMeals/SavedMealsUI';

function SavedMeals() {
  const [totalMealsCount, setTotalMealsCount] = React.useState(0);
  const [currentPage, setCurrentPage] = React.useState(1);
  const [savedMeals, setSavedMeals] = React.useState([])
  let { clientId } = useParams();

  const handlePagination = async (page) => {
    setCurrentPage(page);
  };

  React.useEffect(() => {
    const controller = new AbortController();
    const fetchSavedMeals = async () => {
      try {
        const response = await axiosSecure.get(
          `trainer/meal-plan-list/${clientId}?page=${currentPage}&limit=10`,
          {
            headers: { Authorization: getAuthorizationHeader() },
          }
        );
        setSavedMeals(response?.data?.data);
        setCurrentPage(Number(response?.data.paginationData?.currentPage));
        setTotalMealsCount(Number(response?.data.paginationData?.totalCount));
      } catch (err) {
        toast.error(err.response.data.message);
      }
    };
    fetchSavedMeals();

    return () => controller && controller.abort();
  }, [clientId, currentPage]);

  return (
    <SavedMealsUI
      savedMeals={savedMeals}
      totalMealsCount={totalMealsCount}
      currentPage={currentPage}
      handlePagination={handlePagination}
    />
  );
};

export default SavedMeals;
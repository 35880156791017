export const generateBothPrompt = (client) => {
  if (!client) return null;
  const {
    activity,
    age,
    allergies,
    foodDontLike,
    gender,
    goal,
    height,
    preferenceFood,
    weight,
  } = client;

  return {
    first: `Find my Total Daily Energy Expenditure (TDEE). I am a ${height} ${age} old ${
      gender === 1 ? "Male" : "Female"
    } who weighs ${weight} pounds with a moderate activity level ${activity}. Use this answer to create a full and detailed 4-day meal plan where I am ${goal} at a slow rate. Also provide a rough estimate of the number of calories in each food of each meal. I would also like a rough estimate of the protein, fat, and carb content at the end of each day. I have preferences for ${preferenceFood}, and lots of variety between meals. I am allergic to ${allergies}, so please take extra care in not including these foods: ${foodDontLike}. Include the number of pounds I would lose per week. 
Give the response in valid JSON  objects. The object must be like :
{
  TDEE_details: '',
  plans: [
    {
      title: 'Day 01',
      breakfast: '' ... ",
      snack: '' ... ",
      lunch:'' ... ",
      afternoon_snack: '' ... ",
      dinner: '' ... ",
      snack_2: '' ... ",
      total_calories: "... ",
      "protein": "130g",
      "fat": "75g",
      "carbs": "350g"
    },
    {
      title: 'Day 02',
      breakfast: '' ... ",
      snack: '' ... ",
      lunch:'' ... ",
      afternoon_snack: '' ... ",
      dinner: '' ... ",
      snack_2: '' ... ",
      total_calories: "... ",
      "protein": "130g",
      "fat": "75g",
      "carbs": "350g"
    },
    {
      title: 'Day 03',
      breakfast: '' ... ",
      snack: '' ... ",
      lunch:'' ... ",
      afternoon_snack: '' ... ",
      dinner: '' ... ",
      snack_2: '' ... ",
      total_calories: "... ",
      "protein": "130g",
      "fat": "75g",
      "carbs": "350g"
    },
    {
      title: 'Day 04',
      breakfast: '' ... ",
      snack: '' ... ",
      lunch:'' ... ",
      afternoon_snack: '' ... ",
      dinner: '' ... ",
      snack_2: '' ... ",
      total_calories: "... ",
      "protein": "130g",
      "fat": "75g",
      "carbs": "350g"
    }
  ],
  summary: '',
}
  `,
    second: `Find my Total Daily Energy Expenditure (TDEE). I am a ${height} ${age} old ${
      gender === 1 ? "Male" : "Female"
    } who weighs ${weight} pounds with a moderate activity level ${activity}. Use this answer to create a full and detailed 3-day meal plan for last 3 days of the week so the title should be Day 05 to Day 07 where I am ${goal} at a slow rate. Also provide a rough estimate of the number of calories in each food of each meal. I would also like a rough estimate of the protein, fat, and carb content at the end of each day. I have preferences for ${preferenceFood}, and lots of variety between meals. I am allergic to ${allergies}, so please take extra care in not including these foods: ${foodDontLike}. Include the number of pounds I would lose per week. 
Give the response in valid JSON  objects. The object must be like :
{
  TDEE_details: '',
  plans: [
    {
      title: 'Day 05',
      breakfast: '' ... ",
      snack: '' ... ",
      lunch:'' ... ",
      afternoon_snack: '' ... ",
      dinner: '' ... ",
      snack_2: '' ... ",
      total_calories: "... ",
      "protein": "130g",
      "fat": "75g",
      "carbs": "350g"
    },
    {
      title: 'Day 06',
      breakfast: '' ... ",
      snack: '' ... ",
      lunch:'' ... ",
      afternoon_snack: '' ... ",
      dinner: '' ... ",
      snack_2: '' ... ",
      total_calories: "... ",
      "protein": "130g",
      "fat": "75g",
      "carbs": "350g"
    },
    {
      title: 'Day 07',
      breakfast: '' ... ",
      snack: '' ... ",
      lunch:'' ... ",
      afternoon_snack: '' ... ",
      dinner: '' ... ",
      snack_2: '' ... ",
      total_calories: "... ",
      "protein": "130g",
      "fat": "75g",
      "carbs": "350g"
    }
  ],
  summary: '',
}
`,
  };
};

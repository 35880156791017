import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import { getLogedInUser } from "../../Utility/utility";
import AdminDashboard from "./AdminDashboard";
import TrainerDashboard from "./TrainerDashboard";

const Dashbaord = () => {
  const logedinUser = getLogedInUser();

  return (
    <section className="py-4 dasboard-page">
      <Container>
        <Row>
          <Col>
            {/* <h2 className="text-center">Welcome To Crafted Meals</h2> */}
            {logedinUser && logedinUser.userType === 1 ? (
              <AdminDashboard />
            ) : (
              <TrainerDashboard
                logedinUser={logedinUser}
              />
            )}
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default Dashbaord;

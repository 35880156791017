// @flow strict

import React, { useState } from "react";
import { Button, Form } from "react-bootstrap";
import { FiTrash2 } from "react-icons/fi";
import { toast } from "react-toastify";
import { axiosSecure, getAuthorizationHeader } from "../../api/axios";

const daysOfWeek = [
  { id: 0, name: "Sunday", value: '0' },
  { id: 1, name: "Monday", value: '1' },
  { id: 2, name: "Tuesday", value: '2' },
  { id: 3, name: "Wednesday", value: '3' },
  { id: 4, name: "Thursday", value: '4' },
  { id: 5, name: "Friday", value: '5' },
  { id: 6, name: "Saturday", value: '6' },
];

function SingleSchedule({ item, setClientList, setScheduleInput }) {
  const [schedule, setSchedule] = useState(
    item.isScheduled === 1
      ? {
          first: item.schedulePlan[0].first,
          second: item.schedulePlan[0].second,
        }
      : { first: "", second: "" }
  );

  const handleChangeSchedule = (name, value) => {
    setSchedule((prev) => ({
      ...prev,
      [name]: value,
    }));

    setScheduleInput((prev) => {
      const temp = [...prev];
      const index = temp.findIndex((x) => x.clientId === item._id);

      if (index === -1) {
        const newData = {
          clientId: item._id,
        };

        if (item.isScheduled === 1) {
          newData.scheduleId = item.schedulePlan[0]._id;
          newData["first"] = parseInt(item.schedulePlan[0]["first"]);
          newData["second"] = parseInt(item.schedulePlan[0]["second"]);
        }

        newData[name] = parseInt(value);
        temp.push(newData);
      } else {
        temp[index][name] = parseInt(value);
      }

      return temp;
    });
  };

  const handleUpdateSchedule = async () => {
    if (!schedule.first || !schedule.second) {
      toast.error("Please select both schedules!");
      return;
    }

    const payload = {
      clientId: item._id,
      first: parseInt(schedule.first),
      second: parseInt(schedule.second),
    };

    if (item.isScheduled) {
      payload.scheduleId = item.schedulePlan[0]._id;
    }

    try {
      const response = await axiosSecure.post(
        "/trainer/schedule-meal-plan/add",
        payload,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );

      setClientList((prev) =>
        prev.map((client) =>
          client._id === item._id
            ? {
                ...client,
                isScheduled: 1,
                schedulePlan: [response.data.data],
              }
            : client
        )
      );

      toast.success("Schedule updated successfully!");
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  const handleRemoveSchedule = async (id) => {
    if (!item?.isScheduled) {
      toast.error("This client is not scheduled!");
      return;
    }

    try {
      await axiosSecure.put(
        "/trainer/schedule-meal-plan/remove",
        {
          scheduleId: item.schedulePlan[0]._id,
        },
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );

      setSchedule({});
      setClientList((prev) =>
        prev.map((client) =>
          client._id === id
            ? {
                ...client,
                isScheduled: 0,
                schedulePlan: [],
              }
            : client
        )
      );

      toast.success("Schedule removed successfully!");
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  return (
    <tr>
      <td className="text-start py-3">
        {item.firstName} {item.lastName}
      </td>
      <td className="text-start py-3">{item.email}</td>
      <td className="text-start py-3">
        <Form.Select
          value={schedule.first}
          onChange={(e) => handleChangeSchedule("first", e.target.value)}
        >
          <option>Select first plans schedule</option>
          {daysOfWeek.map((day) => (
            <option value={day.value} key={day.id}>
              {day.name}
            </option>
          ))}
        </Form.Select>
      </td>
      <td className="text-start py-3">
        <Form.Select
          value={schedule.second}
          onChange={(e) => handleChangeSchedule("second", e.target.value)}
        >
          <option>Select second plans schedule</option>
          {daysOfWeek.map((day) => (
            <option value={day.value} key={day.id}>
              {day.name}
            </option>
          ))}
        </Form.Select>
      </td>
      <td className="py-3">
        <div className="d-flex align-items-center gap-2 justify-content-center mt-1">
          <Button size="sm" variant="primary" onClick={handleUpdateSchedule}>
            Set Schedule
          </Button>
          <Button
            size="sm"
            variant="secondary"
            onClick={() => handleRemoveSchedule(item._id)}
            disabled={!item?.isScheduled}
          >
            <FiTrash2 />
          </Button>
        </div>
      </td>
    </tr>
  );
}

export default SingleSchedule;

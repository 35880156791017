import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, ListGroup, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { convertDate } from "../../../Utility/utility";
import { axiosSecure, getAuthorizationHeader } from "../../../api/axios";

function getGender(gender) {
  if (Number(gender) === 1) return "Male";
  else if (Number(gender) === 2) return "Female";
  return "Others";
}

const ViewUserPlan = () => {
  let { planId } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [userPlan, setUserPlan] = useState(null);

  useEffect(() => {
    const controller = new AbortController();
    const fetchUserPlanData = async () => {
      try {
        setLoading(true);
        const response = await axiosSecure.get(`/user-plan/${planId}`, {
          headers: { Authorization: getAuthorizationHeader() },
        });
        setUserPlan(response?.data?.data);
      } catch (err) {
        toast.error(err?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    };
    fetchUserPlanData();

    return () => controller && controller.abort();
  }, [planId]);

  if (loading) {
    return (
      <Container>
        <Row>
          <Col>
            <div className="user_card_wrapper m-auto">
              <div className="d-flex justify-content-center align-items-center h-100">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Row>
          <Col>
            <div className="user_card_wrapper m-auto">
              <Card>
                <Card.Header>Error</Card.Header>
                <Card.Body>
                  <Card.Title className="text-danger">{error}</Card.Title>
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
  return (
    <Container>
      <Row>
        <Col>
          <div className="user_card_wrapper m-auto">
            <Card className="card">
              <Card.Header className="d-flex align-items-center justify-content-between">
                <h4>User Plan Details</h4>
                <Button
                  variant="outline-secondary"
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
              </Card.Header>
              <Card.Body>
                <Card.Title>
                  {userPlan ? `${userPlan?.planName}` : ""}
                </Card.Title>
              </Card.Body>
              <ListGroup className="list-group-flush">
                <ListGroup.Item>
                  <strong>Price: </strong>
                  {userPlan ? `$ ${userPlan?.price}` : ""}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Number Of Client: </strong>
                  {userPlan ? `${userPlan?.numberOfClient}` : ""}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Number Of Meal Plan: </strong>
                  {userPlan ? `${userPlan?.numberOfmealPlan}` : ""}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Number Of Social Media Idea: </strong>
                  {userPlan ? userPlan?.numberOfSocialMediaIdea : ""}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Created At: </strong>
                  {userPlan && userPlan?.createdAt
                    ? convertDate(`${userPlan.createdAt}`)
                    : ""}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Updated At: </strong>
                  {userPlan && userPlan?.updatedAt
                    ? convertDate(`${userPlan.updatedAt}`)
                    : ""}
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ViewUserPlan;

import React, { useEffect, useMemo, useState } from "react";
import { axiosSecure, getAuthorizationHeader } from "../../api/axios";
import { toast } from "react-toastify";
import { Card, Col, Container, Row, Table } from "react-bootstrap";
import PaginationComponent from "../../component/Pagination/Pagination";
import { useNavigate, useSearchParams } from "react-router-dom";
import ScheduleMealPlanHistoryUI from "../../component/ScheduleMealPlanHistoryUI";

const ScheduleMealPlanHistory = () => {
  const limit = 10;
  const [loading, setLoading] = useState(false);
  const [planHistoryData, setPlanHistoryData] = useState(null);
  const [totalHistoryCount, setTotalHistoryCount] = useState(0);

  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const currentPage = useMemo(() => {
    const page = searchParams.get("page");
    if (page) {
      return +page;
    }
    return 1;
  }, [searchParams]);

  const handlePagination = async (page) => {
    navigate(`?page=${page}`);
  };

  useEffect(() => {
    const scheduleMealPlanHistoryList = async () => {
      try {
        setLoading(true);
        const response = await axiosSecure.get(
          `/trainer/scheduled-meal-plan-histories?page=${currentPage}&limit=${limit}`,
          {
            headers: { Authorization: getAuthorizationHeader() },
          }
        );
        setPlanHistoryData(response?.data?.data);
        setTotalHistoryCount(Number(response?.data.paginationData?.totalCount));
      } catch (error) {
        toast.error(error?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    };
    scheduleMealPlanHistoryList();
  }, [currentPage]);

  if (loading) {
    return (
      <Container className="mt-4">
        <Row>
          <Col>
            <div className="user_card_wrapper m-auto">
              <div className="d-flex justify-content-center align-items-center h-100">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <>
      <Container className="my-4">
        <div className="bg-white p-3 mt-4 rounded-2">
          <h2 className="pt-4 pb-3">Schedule Meal Plan History</h2>

          {planHistoryData && planHistoryData.length > 0 ? (
            <Table responsive>
              <thead>
                <tr>
                  <th>Client Name</th>
                  <th>Client Email</th>
                  <th>Email For</th>
                  <th>Email Status</th>
                  <th>Created At</th>
                  <th>View Plan</th>
                </tr>
              </thead>
              <tbody>
                {planHistoryData.map((planData, index) => (
                  <ScheduleMealPlanHistoryUI planData={planData} index={index} />
                ))}
              </tbody>
            </Table>
          ) : (
            <Card>
              <Card.Body>
                <Card.Title className="text-danger text-center">
                  No Schedule Meal Plan History available
                </Card.Title>
              </Card.Body>
            </Card>
          )}

          <div className="d-flex justify-content-end relative bottom-20 me-3 mt-3 ">
            <PaginationComponent
              total={totalHistoryCount}
              itemsPerPage={limit}
              currentPage={currentPage}
              onPageChange={(page) => handlePagination(page)}
            />
          </div>
        </div>        
      </Container>
    </>
  );
};

export default ScheduleMealPlanHistory;

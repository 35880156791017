import React, { useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
// import { Form, Link } from 'react-router-dom';
import { Link, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { axiosOpen } from '../../api/axios';
import logo from "../../assests/images/logo.png";

const FORGOT_PASS_URL = "auth/forgot-password";

const ForgotPassword = () => {
    const navigate = useNavigate();
    const emailRef = useRef();

    const [email, setEmail] = useState("");

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axiosOpen.put(FORGOT_PASS_URL, { email });
            toast.success(response?.data?.message);
            // navigate("/", { replace: true });
        } catch (err) {
            toast.error(err?.response?.data?.message);
        }
    };

    return (
        <>
            <div
                aria-live="polite"
                aria-atomic="true"
                className="position-relative login-page"
            >
                <div className="right-section">
                    <div className="form-signin">
                        <div>
                            <img alt="brand logo" src={logo} width="200" height="50" />
                        </div>
                        <h1 style={{ color: "#111" }}>FORGET PASSWORD</h1>
                        <Form className="form" onSubmit={handleSubmit}>
                            <Form.Floating className="mb-3 ">
                                <Form.Control
                                    id="floatingInputCustom"
                                    type="email"
                                    placeholder="name@example.com"
                                    ref={emailRef}
                                    autoComplete="off"
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                    }}
                                    value={email}
                                    required
                                />
                                <label htmlFor="floatingInputCustom">Email Address</label>
                            </Form.Floating>
                            <Button variant="primary" className="login-btn" type="submit">
                                Submit
                            </Button>
                        </Form>
                    </div>
                </div>

            </div>

            <footer style={{ textAlign: "center", padding: "1rem", backgroundColor: "#fff" }}>
                © {new Date().getFullYear()} Crafted Meals, powered by{" "}
                <span style={{ color: "#ef233c" }}>❤</span>{" "}
                <Link
                    to="https://buildyourai.consulting/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="dark-green"
                >
                    Build Your AI Consulting{" "}
                </Link>
            </footer>
        </>
    )
}

export default ForgotPassword;

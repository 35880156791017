// @flow strict

import * as React from 'react';
import { useState } from 'react';
import { Button, Modal } from 'react-bootstrap';
import { BsFillEyeFill } from 'react-icons/bs';
import { IoCopyOutline } from 'react-icons/io5';
import { MdOutlineDoneAll } from 'react-icons/md';
import { convertDate } from '../../Utility/utility';

function SavedPostCard({ post }) {
  const [show, setShow] = useState(false);
  const [copy, setCopy] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  function handleCopyToClipboard(item) {
    let text = '';

    
    // Add message
    text += item.message + '\n\n';

    text += '🍊 Healthy Diet Idea: \n';
    // Copy diet ideas
    item.dietIdea.forEach((idea) => {
      text += idea + '\n';
    });
  
    // Add a line break between diet and exercise ideas
    text += '\n';
  
    text += '💪 Exercise Idea: \n';
    // Copy exercise ideas
    item.exerciseIdea.forEach((idea) => {
      text += idea + '\n';
    });
  
    // Create a temporary textarea element to hold the text
    const textarea = document.createElement('textarea');
    textarea.value = text;
  
    // Append the textarea to the document
    document.body.appendChild(textarea);
  
    // Copy the text from the textarea
    textarea.select();
    document.execCommand('copy');
  
    // Remove the textarea from the document
    document.body.removeChild(textarea);
    setCopy(true);
  }

  return (
    <>
      <div className="saved-post-content p-3 rounded mb-3">
        <p className="">{post.message}</p>
        <div className="d-flex justify-content-between align-items-center">
          <span><strong>Created Date: </strong> {convertDate(post.createdAt)}</span>
          <Button
            type="button"
            variant="outline"
            onClick={handleShow}
            className="btn outlinde-button-theme p-1 px-3"
          >
            <BsFillEyeFill />
          </Button>
        </div>
      </div>
      <Modal show={show} onHide={handleClose} centered>
        <div className="output-container rounded">
          <div className="social-post-output p-3">

            <div className="">
              <p
                className="mb-3 p-2 media-post-content rounded"
              >
                {post?.message}
              </p>
              <h5>🍊 Healthy Diet Idea:</h5>
              <ul>
                {post?.dietIdea?.map((idea, i) => (
                  <li key={i}>{idea}</li>
                ))}
              </ul>
              <h5>💪 Exercise Idea:</h5>
              <ul>
                {post?.exerciseIdea?.map((idea, j) => (
                  <li key={j}>{idea}</li>
                ))}
              </ul>

              <div className="d-flex justify-content-end gap-2 mt-3">
                <Button
                  className="border dark-green btn-secondary"
                  onClick={() => handleCopyToClipboard(post)}
                >
                  {copy ? (
                    <>
                      <MdOutlineDoneAll />{" "}
                      <span className="ps-1">Copied</span>
                    </>
                  ) : (
                    <>
                      <IoCopyOutline />{" "}
                      <span className="ps-1">Copy</span>
                    </>
                  )}
                </Button>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SavedPostCard;
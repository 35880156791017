import React, { useEffect, useState } from "react";
import "./view.scss";
import { useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Card } from "react-bootstrap";
import ListGroup from "react-bootstrap/ListGroup";
import { axiosSecure, getAuthorizationHeader } from "../../../api/axios";
import { convertDate } from "../../../Utility/utility";
import Button from "react-bootstrap/Button";
import { toast } from "react-toastify";

const ViewUser = () => {
  let { userId } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [user, setUser] = useState(null);

  useEffect(() => {
    const controller = new AbortController();
    const fetchUserData = async () => {
      try {
        setLoading(true);
        const response = await axiosSecure.get(`/admin/users/${userId}`, {
          headers: { Authorization: getAuthorizationHeader() },
        });
        setUser(response?.data?.data);
      } catch (err) {
        toast.error(err?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    };
    fetchUserData();

    return () => controller && controller.abort();
  }, [userId]);

  if (loading) {
    return (
      <Container>
        <Row>
          <Col>
            <div className="user_card_wrapper m-auto">
              <div className="d-flex justify-content-center align-items-center h-100">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Row>
          <Col>
            <div className="user_card_wrapper m-auto">
              <Card>
                <Card.Header className="d-flex align-items-center justify-content-between">
                  <h4>Error</h4>
                  <Button
                    variant="outline-secondary"
                    onClick={() => navigate(-1)}
                  >
                    Back
                  </Button>
                </Card.Header>
                <Card.Body>
                  <Card.Title className="text-danger">{error}</Card.Title>
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <Container>
      <Row>
        <Col>
          <div className="user_card_wrapper m-auto">
            <Card>
              <Card.Header className="d-flex align-items-center justify-content-between">
                <h4>Trainer Details</h4>
                <Button
                  variant="outline-secondary"
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
              </Card.Header>

              <Card.Body>
                <Card.Title>
                  {user ? `${user.firstName} ${user.lastName}` : ""}
                </Card.Title>
              </Card.Body>
              <ListGroup className="list-group-flush">
                <ListGroup.Item>
                  <strong>Email: </strong>
                  {user ? `${user.email}` : ""}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Organization Name: </strong>
                  {user?.organizationName ?? "---"}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Phone Number: </strong>
                  {user?.phoneNumber ?? "---"}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Created At: </strong>
                  {user && user?.createdAt
                    ? convertDate(`${user.createdAt}`)
                    : ""}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Updated At: </strong>
                  {user && user?.updatedAt
                    ? convertDate(`${user.updatedAt}`)
                    : ""}
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ViewUser;

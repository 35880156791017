// @flow strict

import * as React from "react";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import useTrainerPlan from "../../Hooks/usePlan";
import { axiosSecure, getAuthorizationHeader } from "../../api/axios";
import WarningModal from "../../component/MealPlaning/WarningModal";
import SocialMediaIdeaUI from "../../component/SocialMediaIdea/SocialMediaIdeaUI";
import { getUserDetails } from "../../service";

function SocialMediaIdeaGenerator() {
  const [inputData, setInputData] = useState({});
  const [loading, setLoading] = useState(false);
  const [messages, setMessages] = useState(null);
  const [copied, setCopied] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const { remaining } = useTrainerPlan();

  const handleCloseModal = () => setShowModal(false);

  const { user } = getUserDetails();

  const handleOnchange = (e) => {
    setInputData((prev) => {
      const temp = JSON.parse(JSON.stringify(prev));
      temp[e.target.name] = e.target.value;
      return temp;
    });
  };

  const generateIdeaByChatGpt = async (prompt) => {
    const payload = JSON.stringify({
      message: prompt,
      generationFor: "socialMedia",
    });

    try {
      const response = await axiosSecure.post(
        "/trainer/generate-digital-post",
        payload,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );

      const answer = response?.data?.data;
      if (!answer) {
        toast.error("An error occurred. Please try again later.");
        return;
      }

      const jsonResponse = answer.slice(
        answer.indexOf("{"),
        answer.lastIndexOf("}") + 1
      );

      try {
        const parsedObj = JSON.parse(jsonResponse);
        if (
          !parsedObj?.message ||
          parsedObj?.dietIdea?.length === 0 ||
          parsedObj?.exerciseIdea?.length === 0
        ) {
          generateIdeaByChatGpt(prompt);
        } else {
          setCopied([]);
          setMessages(parsedObj);
          setLoading(false);
        }
      } catch (error) {
        generateIdeaByChatGpt(prompt);
      }
    } catch (err) {
      console.error(err);
      setLoading(false);
      toast.error(err?.response?.data?.message);
    }
  };

  const handleGenerateIdea = async (event) => {
    event.preventDefault();

    if (remaining?.socialMediaIdeaRemaining === 0) {
      setShowModal(true);
      return;
    }

    setLoading(true);

    const { targetCustomers, location, month, socialMedia } = inputData;

    if (!targetCustomers || !location || !month || !socialMedia) {
      toast.error("Please fill in all fields.");
      setLoading(false);
      return;
    }

    const prompt = `I want you to act like my Social Media Manager. My name is ${
      user ? user.firstName + " " + user.lastName : "Chris H"
    }. I am a personal trainer based in ${
      inputData?.location
    }. My target clients are: ${inputData?.targetCustomers}. I post on ${
      inputData?.socialMedia
    } about “Healthy Diet” and “Exercise”. Please give me 1 post for the month of ${
      inputData?.month
    } that I can post on ${
      inputData?.socialMedia
    }. Please use a casual tone and ensure that your message includes 50 words. Additionally, provide 5 bullet points for a "Healthy Diet Idea" and 5 bullet points for an "Exercise Idea". You may also include emojis in every statement.
Please respond with a valid JSON objects like: { message: "", dietIdea: [], exerciseIdea: []}.`;

    generateIdeaByChatGpt(prompt);
  };

  function handleCopyToClipboard(item) {
    let text = "";

    // Add message
    text += item.message + "\n\n";

    text += "🍊 Healthy Diet Idea: \n";
    // Copy diet ideas
    item.dietIdea.forEach((idea) => {
      text += idea + "\n";
    });

    // Add a line break between diet and exercise ideas
    text += "\n";

    text += "💪 Exercise Idea: \n";
    // Copy exercise ideas
    item.exerciseIdea.forEach((idea) => {
      text += idea + "\n";
    });

    // Create a temporary textarea element to hold the text
    const textarea = document.createElement("textarea");
    textarea.value = text;

    // Append the textarea to the document
    document.body.appendChild(textarea);

    // Copy the text from the textarea
    textarea.select();
    document.execCommand("copy");

    // Remove the textarea from the document
    document.body.removeChild(textarea);

    setCopied((prev) => {
      const temp = JSON.parse(JSON.stringify(prev));
      if (!temp.includes(item.id)) {
        temp.push(item.id);
      }
      return temp;
    });
  }

  const saveGeneratedPost = async (porst) => {
    const payload = JSON.stringify(porst);
    try {
      const response = await axiosSecure.post(
        "/trainer/social-media-post/add",
        payload,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );
      toast.success(response?.data?.message);
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  };

  useEffect(() => {
    if (remaining?.socialMediaIdeaRemaining === 0) {
      setShowModal(true);
    }
  }, [remaining]);

  return (
    <>
      <SocialMediaIdeaUI
        handleGenerateIdea={handleGenerateIdea}
        handleOnchange={handleOnchange}
        message={messages}
        inputData={inputData}
        loading={loading}
        handleCopyToClipboard={handleCopyToClipboard}
        copied={copied}
        saveGeneratedPost={saveGeneratedPost}
      />
      <Modal
        scrollable={true}
        show={showModal}
        onHide={handleCloseModal}
        centered
      >
        <WarningModal type="social" handleCloseModal={handleCloseModal} />
      </Modal>
    </>
  );
}

export default SocialMediaIdeaGenerator;

// @flow strict

import * as React from 'react';
import { Button } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import logo from "../../assests/images/craftedMealLogo.png";

function WarningModal({ handleCloseModal, type }) {
  const socialMessage = "You have reached the maximum number of social media posts generated based on your current subscription plan. To generate more social media posts, please consider upgrading your subscription.";
  const MealMessage = "You have reached the maximum number of meal plans based on your current subscription plan. To generate more meal plans, please consider upgrading your subscription.";


  const navigate = useNavigate();

  return (
    <div className="plan-warning-modal p-4">
      <img className="warning-logo mb-3" alt="Crafted Meals" src={logo} />
      <p className="warning-text">
        {type === "social" ? socialMessage : MealMessage}
      </p>
      <div className="d-flex justify-content-center align-content-end">
        <Button
          variant="success"
          onClick={() => navigate("/pricing")}
          className='btn upgrade-btn px-4 my-2'>
          Upgrade Now
        </Button>
      </div>
      <div className="d-flex justify-content-center align-content-end">
        <Button
          variant="text"
          onClick={handleCloseModal}
          className='no-upgrade-btn p-0'
        >
          No Thanks
        </Button>
      </div>
    </div>
  );
};

export default WarningModal;
const convertDate = (timestamp) => {
  if (timestamp) {
    const date = new Date(timestamp);
    const month = date.getMonth() + 1; // getMonth() returns 0-based month index
    const day = date.getDate();
    const year = date.getFullYear();
    return `${month.toString().padStart(2, "0")}-${day
      .toString()
      .padStart(2, "0")}-${year}`;
  } else {
    return "";
  }
};

const getLogedInUser = () =>
  localStorage.getItem("userDetails")
    ? JSON.parse(localStorage.getItem("userDetails")).user
    : null;

const isValidMealPlan = (mealPlanObj) => {
  const firstPlan = mealPlanObj.plans[0];
  if (firstPlan) {
    const {
      afternoon_snack,
      breakfast,
      carbs,
      dinner,
      fat,
      lunch,
      protein,
      snack,
      snack_2,
      total_calories,
    } = firstPlan;
    return Boolean(
      afternoon_snack &&
        afternoon_snack.length > 2 &&
        breakfast &&
        breakfast.length > 2 &&
        carbs &&
        carbs.length > 2 &&
        dinner &&
        dinner.length > 2 &&
        fat &&
        fat.length > 2 &&
        lunch &&
        lunch.length > 2 &&
        snack &&
        snack.length > 2 &&
        snack_2 &&
        snack_2.length > 2 &&
        protein &&
        protein.length > 2 &&
        total_calories &&
        total_calories.length > 2
    );
  }
  return false;
};
function formatStringWithCount(count, string) {
  if (!count) return `${count} ${string}`;
  return count > 1 ? `${count} ${string}s` : `${count} ${string}`;
}

export { convertDate, formatStringWithCount, getLogedInUser, isValidMealPlan };

import React, { useRef, useState } from "react";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
import Toast from "react-bootstrap/Toast";
import ToastContainer from "react-bootstrap/ToastContainer";
import { BiErrorCircle } from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import { axiosOpen } from "../../api/axios";
import logo from "../../assests/images/logo.png";
import { toast } from "react-toastify";
import { Footer } from "../../component";
import { useFormik } from 'formik';
import * as yup from 'yup';

const LOGIN_URL = "auth/reset-password";

const ResetPassword = () => {
    const navigate = useNavigate();

    const resetPassToken = localStorage.getItem('resetPassToken');

    const schema = yup.object().shape({
        password: yup
            .string()
            .required('New Password is required')
            .min(8, 'New Password must be at least 8 characters'),
        confirmPassword: yup
            .string()
            .required('Confirm Password is required')
            .oneOf([yup.ref('password'), null], 'Passwords must match'),
    });

    const formik = useFormik({
        initialValues: {
            password: '',
            confirmPassword: '',
            resetToken: resetPassToken,
        },
        validationSchema: schema,
        onSubmit: async (values) => {
            // Handle form submission
            try {
                const response = await axiosOpen.put(LOGIN_URL, values);
                toast.success(response?.data?.message);
                navigate("/login", { replace: true });
            } catch (err) {
                toast.error(err?.response?.data?.message);
            }
        },
    });

    return (
        <>
            <div
                aria-live="polite"
                aria-atomic="true"
                className="position-relative login-page"
            >
                <div className="right-section">
                    <div className="form-signin">
                        <div>
                            <img alt="brand logo" src={logo} width="200" height="50" />
                        </div>
                        <h1 style={{ color: "#111" }}>RESET PASSWORD</h1>
                        <Form className="form" onSubmit={formik.handleSubmit}>
                            <Form.Group controlId="password">
                                <Form.Label>New Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Enter new password"
                                    name="password"
                                    value={formik.values.password}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    isInvalid={formik.touched.password && formik.errors.password}
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.password}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Form.Group controlId="confirmPassword" className="my-3">
                                <Form.Label>Confirm Password</Form.Label>
                                <Form.Control
                                    type="password"
                                    placeholder="Confirm new password"
                                    name="confirmPassword"
                                    value={formik.values.confirmPassword}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                    isInvalid={
                                        formik.touched.confirmPassword && formik.errors.confirmPassword
                                    }
                                />
                                <Form.Control.Feedback type="invalid">
                                    {formik.errors.confirmPassword}
                                </Form.Control.Feedback>
                            </Form.Group>

                            <Button variant="primary" type="submit">
                                Reset Password
                            </Button>
                        </Form>
                    </div>
                </div>

            </div>

            <footer style={{ textAlign: "center", padding: "1rem", backgroundColor: "#fff" }}>
                © {new Date().getFullYear()} Crafted Meals, powered by{" "}
                <span style={{ color: "#ef233c" }}>❤</span>{" "}
                <Link
                    to="https://buildyourai.consulting/"
                    target="_blank"
                    rel="noopener noreferrer"
                    className="dark-green"
                >
                    Build Your AI Consulting{" "}
                </Link>
            </footer>
        </>
    );
};

export default ResetPassword;



import React, { useEffect, useState } from "react";
import { Card, Col, Container, Row } from "react-bootstrap";

import { Button, ButtonGroup } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { convertDate } from "../../../Utility/utility";
import { axiosSecure, getAuthorizationHeader } from "../../../api/axios";
import DeleteModal from "../../../component/Modal";
import PaginationComponent from "../../../component/Pagination/Pagination";
import "./listUser.scss";

const ListUser = () => {
  const [limit, setLimit] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const [dataLoader, setDataLoader] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState("");

  const [userList, setUserList] = useState(null);
  const [totalUserCount, setTotalUserCount] = useState(0);
  // Modal State
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  const fetchUserList = async () => {
    try {
      setDataLoader(true);
      const response = await axiosSecure.get(
        `/admin/users?page=${currentPage}&limit=${limit}`,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );
      setUserList(response?.data?.data);
    } catch (err) {
      toast.error(err?.response?.data?.message);
    } finally {
      setDataLoader(false);
    }
  };

  const handleDelete = async () => {
    if (itemToDelete) {
      const requestPayload = {
        uid: itemToDelete._id,
        userType: itemToDelete.userType,
      };
      try {
        const response = await axiosSecure.put(
          "/admin/user/delete",
          requestPayload,
          {
            headers: { Authorization: getAuthorizationHeader() },
          }
        );
        toast.success(response?.data?.message);
        fetchUserList();
      } catch (err) {
        toast.error(err?.response?.data?.message);
      } finally {
        setItemToDelete(null);
      }
    }
    setShowDeleteModal(false);
  };

  const handleDeleteClick = (item) => {
    setShowDeleteModal(true);
    setItemToDelete(item);
  };

  useEffect(() => {
    const controller = new AbortController();
    const fetchUserData = async () => {
      try {
        setDataLoader(true);
        const response = await axiosSecure.get(`/admin/users?page=1&limit=10`, {
          headers: { Authorization: getAuthorizationHeader() },
        });
        setUserList(response?.data?.data);
        setLimit(Number(response?.data?.paginationData?.perPage));
        setCurrentPage(Number(response?.data?.paginationData?.currentPage));
        setTotalUserCount(Number(response?.data?.paginationData?.totalCount));
      } catch (err) {
        toast.error(err?.response?.data?.message);
      } finally {
        setDataLoader(false);
      }
    };
    fetchUserData();

    return () => controller && controller.abort();
  }, []);

  const handlePagination = async (page) => {
    setCurrentPage(page);
  };

  const handleLimitChange = (e) => {
    setLimit(e.target.value);
    setCurrentPage(1);
  };

  useEffect(() => {
    const fetchUserListAsNeed = async () => {
      try {
        setDataLoader(true);
        const response = await axiosSecure.get(
          `/admin/users?page=${currentPage}&limit=${limit}`,
          {
            headers: { Authorization: getAuthorizationHeader() },
          }
        );
        setUserList(response?.data?.data);
      } catch (err) {
        toast.error(err?.response?.data?.message);
      } finally {
        setDataLoader(false);
      }
    };
    fetchUserListAsNeed();
  }, [currentPage, limit]);

  if (dataLoader) {
    return (
      <Container className="mt-4">
        <Row>
          <Col>
            <div className="user_card_wrapper m-auto">
              <div className="d-flex justify-content-center align-items-center h-100">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  if (error) {
    return (
      <Container className="mt-4">
        <Row>
          <Col>
            <div className="user_card_wrapper m-auto">
              <Card>
                <Card.Header>Error</Card.Header>
                <Card.Body>
                  <Card.Title className="text-danger">{error}</Card.Title>
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <>
      <Container className="flex-grow-1 mt-4">
        <div className="bg-white mt-1 p-3 rounded-2">
          <div className="row align-items-centner pt-4 pb-3">
            <div className="col-lg-7 col-md-7 col-sm-7 col-7">
              <h2>Trainer Listing</h2>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-5 col-5">
              <div className="d-flex justify-content-end">
                <Link to="/user/add" replace className="btn btn-primary">
                  Add Trainer
                </Link>
              </div>
            </div>
          </div>
          {userList && userList?.length > 0 ? (
            <div className="user-table">
              <Table responsive>
                <thead>
                  <tr>
                    <th>Full Name</th>
                    <th>Email</th>
                    <th>Organization Name</th>
                    <th>Phone</th>
                    <th>Created At</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody className="table-group-divider">
                  {dataLoader ? (
                    <div className="d-flex justify-content-center align-items-center h-100">
                      <div className="spinner-border" role="status">
                        <span className="visually-hidden">Loading...</span>
                      </div>
                    </div>
                  ) : (
                    userList.map((item, index) => (
                      <tr key={item._id}>
                        <td>
                          {item?.firstName} {item?.lastName}
                        </td>
                        <td>{item?.email}</td>
                        <td>{item?.organizationName ?? "---"}</td>
                        <td>{item?.phoneNumber ?? "---"}</td>
                        <td>{convertDate(item?.createdAt)}</td>
                        <td className="text-center">
                          <ButtonGroup
                            aria-label="Basic example"
                            className="buttonGroup"
                          >
                            <Button
                              size="sm"
                              variant="light"
                              onClick={() =>
                                navigate(`/user/edit/${item?._id}`)
                              }
                            >
                              <i className="bi bi-pencil-square"></i>
                            </Button>
                            <Button
                              size="sm"
                              variant="light"
                              onClick={() =>
                                navigate(`/user/view/${item?._id}`)
                              }
                            >
                              <i className="bi bi-eye"></i>
                            </Button>
                            <Button
                              size="sm"
                              variant="light"
                              onClick={() => handleDeleteClick(item)}
                            >
                              <i className="bi bi-trash"></i>
                            </Button>
                          </ButtonGroup>
                        </td>
                      </tr>
                    ))
                  )}
                </tbody>
              </Table>

              {/* Delete Confirm Modal */}
              <DeleteModal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                onDelete={handleDelete}
                // itemName={itemToDelete && itemToDelete.name}
                itemName={`Are you sure delete this user?`}
              />
            </div>
          ) : (
            <Row>
              <Col>
                <Table>
                  <thead>
                    <tr>
                      <th>Full Name</th>
                      <th>Email</th>
                      <th>Created At</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                </Table>
                <Card>
                  <Card.Body>
                    <Card.Title className="text-danger text-center">
                      No User data available
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
          <div className="d-flex justify-content-end relative bottom-20 me-3 mt-3 ">
            <PaginationComponent
              total={totalUserCount}
              itemsPerPage={limit}
              currentPage={currentPage}
              onPageChange={(page) => handlePagination(page)}
            />
          </div>
        </div>
      </Container>
    </>
  );
};

export default ListUser;

import React, { useState } from "react";
import { convertDate } from "../../Utility/utility";
import { BsFillEyeFill } from "react-icons/bs";
import { Button, Modal } from "react-bootstrap";
import { AiOutlineClose } from "react-icons/ai";

const ScheduleMealPlanHistoryUI = ({ planData, index }) => {
  const plans = planData?.plans ? JSON.parse(planData.plans) : [];

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  return (
    <>
      <tr key={index}>
        <td>
          {planData?.clientDetails["firstName"]}{" "}
          {planData?.clientDetails["lastName"]}
        </td>
        <td>{planData?.clientEmail}</td>
        <td>
          {planData?.mealDaysCount === 4 ? (
            <p>First {planData?.mealDaysCount} Days </p>
          ) : (
            <p>Last {planData?.mealDaysCount} Days </p>
          )}
        </td>
        <td>
          {planData?.emailStatus === 2 ? (
            <span style={{ backgroundColor: "#4ADE80" }} className="badge">
              Completed
            </span>
          ) : (
            <span style={{ backgroundColor: "#38BDF8" }} className="badge">
              Pending
            </span>
          )}
        </td>
        <td>{convertDate(planData?.createdAt)}</td>
        <td>
          <Button
            type="button"
            variant="outline"
            onClick={handleShow}
            className="btn outlinde-button-theme p-1 px-3"
          >
            <BsFillEyeFill />
          </Button>
        </td>
      </tr>

      {/* Plans Modal */}
      <Modal
        size="lg"
        scrollable={true}
        show={show}
        onHide={handleClose}
        centered
      >
        <div className="social-post-output p-3 p-md-4">
          <div className="social-post-output">
            {/* <h3 className="mb-3">{`Your ${
              planData?.TDEE_details2 ? "First Four" : "7"
            } Days Meal Plans:`}</h3> */}


            <div className="d-flex justify-content-between">
            <h3>
              {planData?.mealDaysCount === 4 ? (
                <p>First {planData?.mealDaysCount} Days Meal Plans </p>
              ) : (
                <p>Last {planData?.mealDaysCount} Days Meal Plans</p>
              )}
            </h3>
             <div className="d-flex justify-content-center" style={{cursor: "pointer", fontSize: "1.5rem"}} onClick={() => handleClose()}> <AiOutlineClose /> </div>
            </div>

            <p className="fst-italic">{planData?.TDEE_details}</p>
            <div className="">
              {plans?.length > 0 &&
                plans?.map((plan, index) => (
                  <div key={index} className="mb-5">
                    <h5 className="">{plan?.title}</h5>
                    <ul>
                      <li className="mb-2">
                        <span style={{ fontWeight: 500 }}>Breakfast: </span>
                        {plan?.breakfast}
                      </li>
                      <li className="my-2">
                        <span style={{ fontWeight: 500 }}>Snack: </span>
                        {plan?.snack}
                      </li>
                      <li className="my-2">
                        <span style={{ fontWeight: 500 }}>Lunch: </span>
                        {plan?.lunch}
                      </li>
                      <li className="my-2">
                        <span style={{ fontWeight: 500 }}>Snack: </span>
                        {plan?.afternoon_snack}
                      </li>
                      <li className="my-2">
                        <span style={{ fontWeight: 500 }}>Dinner: </span>
                        {plan?.dinner}
                      </li>
                      <li className="my-2">
                        <span style={{ fontWeight: 500 }}>Snack: </span>
                        {plan?.snack_2}
                      </li>
                    </ul>

                    <p className="my-2">
                      <span style={{ fontWeight: 500 }}>Total Calories: </span>
                      {plan?.total_calories}
                    </p>
                    <p className="my-2">
                      <span style={{ fontWeight: 500 }}>Fat: </span>
                      {plan?.fat}
                    </p>
                    <p className="my-2">
                      <span style={{ fontWeight: 500 }}>Carbs: </span>
                      {plan?.carbs}
                    </p>
                    <p className="my-2">
                      <span style={{ fontWeight: 500 }}>Protein: </span>
                      {plan?.protein}
                    </p>
                  </div>
                ))}
            </div>
            <p style={{ fontWeight: 500 }}>{planData?.summary}</p>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default ScheduleMealPlanHistoryUI;

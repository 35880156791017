// @flow strict

import * as React from "react";
import { Button, Modal } from "react-bootstrap";
import { BsFillEyeFill } from "react-icons/bs";
import { IoCopyOutline } from "react-icons/io5";
import { MdOutlineDoneAll } from "react-icons/md";
import { convertDate } from "../../Utility/utility";

function SavedMealCard({ meal }) {
  const [show, setShow] = React.useState(false);
  const [copy, setCopy] = React.useState(false);
  const [isCopied, markAsCopied] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const plans = meal?.plans ? JSON.parse(meal.plans) : [];
  const plans2 = meal?.plans2 ? JSON.parse(meal.plans2) : [];


  function handleCopyToClipboard(mealPlan, orderFlag) {
    let text = "";

    // Add TDEE details
    text +=
      (orderFlag === "first" ? mealPlan.TDEE_details : mealPlan.TDEE_details2) +
      " \n\n";
    const copiedPlan = orderFlag === "first" ? plans : plans2;
    // Loop through each plan
    copiedPlan.forEach((plan) => {
      // Add plan title
      text += plan.title + " :\n\n";

      // Add meals and snacks
      text += "Breakfast: " + plan.breakfast + "\n";
      text += "Snack: " + plan.snack + "\n";
      text += "Lunch: " + plan.lunch + "\n";
      text += "Afternoon Snack: " + plan.afternoon_snack + "\n";
      text += "Dinner: " + plan.dinner + "\n";
      text += "Snack 2: " + plan.snack_2 + "\n";

      // Add total calories and macronutrients
      text += "Total Calories: " + plan.total_calories + "\n";
      text += "Protein: " + plan.protein + "\n";
      text += "Fat: " + plan.fat + "\n";
      text += "Carbs: " + plan.carbs + "\n";

      // Add line break between plans
      text += "\n";
    });

    // Add summary
    text += orderFlag === "first" ? mealPlan.summary : mealPlan.summary2;

    // Copy text to clipboard
    const el = document.createElement("textarea");
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    if (orderFlag === "first") setCopy(true);
    else markAsCopied(true);
  }

  return (
    <>
      <div className="saved-post-content p-3 rounded mb-3">
        <div className="social-post-output">
          <p className="">{meal.summary}</p>
        </div>
        <div className="d-flex justify-content-between align-items-center">
          <span>
            <strong>Created Date: </strong>
            {convertDate(meal.createdAt)}
          </span>
          <Button
            type="button"
            variant="outline"
            onClick={handleShow}
            className="btn outlinde-button-theme p-1 px-3"
          >
            <BsFillEyeFill />
          </Button>
        </div>
      </div>
      <Modal
        size="lg"
        scrollable={true}
        show={show}
        onHide={handleClose}
        centered
      >
        <div className="social-post-output p-3 p-md-4">
          <div className="social-post-output">
            <h3 className="mb-3">
              {`Your ${(plans.length === 4) ? "First Four" : (plans.length === 3 ? "Last Three" : "7")
                } Days Meal Plans:`}</h3>
            <p className="fst-italic">{meal?.TDEE_details}</p>
            <div className="">
              {plans?.length > 0 &&
                plans?.map((plan, index) => (
                  <div key={index} className="mb-5">
                    <h5 className="">{plan?.title}</h5>
                    <ul>
                      <li className="mb-2">
                        <span style={{ fontWeight: 500 }}>Breakfast: </span>
                        {plan?.breakfast}
                      </li>
                      <li className="my-2">
                        <span style={{ fontWeight: 500 }}>Snack: </span>
                        {plan?.snack}
                      </li>
                      <li className="my-2">
                        <span style={{ fontWeight: 500 }}>Lunch: </span>
                        {plan?.lunch}
                      </li>
                      <li className="my-2">
                        <span style={{ fontWeight: 500 }}>Snack: </span>
                        {plan?.afternoon_snack}
                      </li>
                      <li className="my-2">
                        <span style={{ fontWeight: 500 }}>Dinner: </span>
                        {plan?.dinner}
                      </li>
                      <li className="my-2">
                        <span style={{ fontWeight: 500 }}>Snack: </span>
                        {plan?.snack_2}
                      </li>
                    </ul>

                    <p className="my-2">
                      <span style={{ fontWeight: 500 }}>Total Calories: </span>
                      {plan?.total_calories}
                    </p>
                    <p className="my-2">
                      <span style={{ fontWeight: 500 }}>Fat: </span>
                      {plan?.fat}
                    </p>
                    <p className="my-2">
                      <span style={{ fontWeight: 500 }}>Carbs: </span>
                      {plan?.carbs}
                    </p>
                    <p className="my-2">
                      <span style={{ fontWeight: 500 }}>Protein: </span>
                      {plan?.protein}
                    </p>
                  </div>
                ))}
            </div>
            <p style={{ fontWeight: 500 }}>{meal?.summary}</p>
          </div>
          <div className="d-flex justify-content-end gap-2 mt-3">
            <Button
              className="border dark-green btn-secondary"
              onClick={() => handleCopyToClipboard(meal, "first")}
            >
              {copy ? (
                <>
                  <MdOutlineDoneAll /> <span className="ps-1">Copied</span>
                </>
              ) : (
                <>
                  <IoCopyOutline /> <span className="ps-1">Copy</span>
                </>
              )}
            </Button>
          </div>
          {meal?.TDEE_details2 && plans2.length > 0 && (
            <>
              <div className="social-post-output">
                <h3 className="mb-3">Your Last Three Days Meal Plans:</h3>
                <p className="fst-italic">{meal?.TDEE_details2}</p>
                <div className="">
                  {plans2?.length > 0 &&
                    plans2?.map((plan, index) => (
                      <div key={index} className="mb-5">
                        <h5 className="">{plan?.title}</h5>
                        <ul>
                          <li className="mb-2">
                            <span style={{ fontWeight: 500 }}>Breakfast: </span>
                            {plan?.breakfast}
                          </li>
                          <li className="my-2">
                            <span style={{ fontWeight: 500 }}>Snack: </span>
                            {plan?.snack}
                          </li>
                          <li className="my-2">
                            <span style={{ fontWeight: 500 }}>Lunch: </span>
                            {plan?.lunch}
                          </li>
                          <li className="my-2">
                            <span style={{ fontWeight: 500 }}>Snack: </span>
                            {plan?.afternoon_snack}
                          </li>
                          <li className="my-2">
                            <span style={{ fontWeight: 500 }}>Dinner: </span>
                            {plan?.dinner}
                          </li>
                          <li className="my-2">
                            <span style={{ fontWeight: 500 }}>Snack: </span>
                            {plan?.snack_2}
                          </li>
                        </ul>

                        <p className="my-2">
                          <span style={{ fontWeight: 500 }}>
                            Total Calories:{" "}
                          </span>
                          {plan?.total_calories}
                        </p>
                        <p className="my-2">
                          <span style={{ fontWeight: 500 }}>Fat: </span>
                          {plan?.fat}
                        </p>
                        <p className="my-2">
                          <span style={{ fontWeight: 500 }}>Carbs: </span>
                          {plan?.carbs}
                        </p>
                        <p className="my-2">
                          <span style={{ fontWeight: 500 }}>Protein: </span>
                          {plan?.protein}
                        </p>
                      </div>
                    ))}
                </div>
                <p style={{ fontWeight: 500 }}>{meal?.summary2}</p>
              </div>
              <div className="d-flex justify-content-end gap-2 mt-3">
                <Button
                  className="border dark-green btn-secondary"
                  onClick={() => handleCopyToClipboard(meal, "last")}
                >
                  {isCopied ? (
                    <>
                      <MdOutlineDoneAll /> <span className="ps-1">Copied</span>
                    </>
                  ) : (
                    <>
                      <IoCopyOutline /> <span className="ps-1">Copy</span>
                    </>
                  )}
                </Button>
              </div>
            </>
          )}
        </div>
      </Modal>
    </>
  );
}

export default SavedMealCard;

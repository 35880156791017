// @flow strict

import * as React from "react";
import { Button, Card, Container, Form, Spinner } from "react-bootstrap";
import { IoCopyOutline } from "react-icons/io5";
import { MdOutlineDoneAll, MdSave } from "react-icons/md";

function SocialMediaIdeaUI(props) {
  const {
    handleGenerateIdea,
    handleOnchange,
    message,
    inputData,
    loading,
    handleCopyToClipboard,
    copied,
    saveGeneratedPost
  } = props;

  return (
    <Container className="d-flex justify-content-center my-4 social-media-section">
      <Card className="social-media-idea-generator shadow border-0 rounded">
        <h3 className="text-center mb-4">Social Media Post Generator</h3>

        <div className="">
          <Form onSubmit={handleGenerateIdea}>
            <Form.Group className="mb-3">
              <Form.Label>Target Customers</Form.Label>
              <Form.Control
                onChange={handleOnchange}
                placeholder="Middle age Professionals"
                name="targetCustomers"
                type="text"
              />
            </Form.Group>
            <Form.Group className="mb-3">
              <Form.Label>Location</Form.Label>
              <Form.Control
                onChange={handleOnchange}
                placeholder="New York"
                name="location"
                type="text"
              />
            </Form.Group>

            <Form.Group className="mb-3" controlId="formMonthPicker">
              <Form.Label>Select a Month</Form.Label>
              <Form.Control
                as="select"
                value={inputData?.month}
                name="month"
                onChange={handleOnchange}
              >
                <option value="">Select a month...</option>
                <option value="January">January</option>
                <option value="February">February</option>
                <option value="March">March</option>
                <option value="April">April</option>
                <option value="May">May</option>
                <option value="June">June</option>
                <option value="July">July</option>
                <option value="August">August</option>
                <option value="September">September</option>
                <option value="October">October</option>
                <option value="November">November</option>
                <option value="December">December</option>
              </Form.Control>
            </Form.Group>

            <Form.Group className="mb-3">
              <Form.Label>Social Media</Form.Label>
              <Form.Control
                onChange={handleOnchange}
                placeholder="Facebook, Twitter, and Instagram"
                name="socialMedia"
                type="text"
              />
            </Form.Group>

            {loading ? (
              <div className="d-flex  justify-content-center mt-4">
                <Button
                  type="submit"
                  className="btn btn-rimary"
                  disabled={loading}
                >
                  <Spinner
                    as="span"
                    animation="grow"
                    size="sm"
                    role="status"
                    aria-hidden="true"
                  />
                  <span className="ps-1">
                    Just a moment, we're crafting your social media post.
                  </span>
                </Button>
              </div>
            ) : (
              <div className="d-flex justify-content-center mt-4">
                <Button
                  type="submit"
                  className="btn btn-rimary"
                  disabled={loading}
                >
                  Generate
                </Button>
              </div>
            )}
          </Form>
        </div>
        {
          message && (
            <>
              <div className="border-bottom my-5"></div>
              <div className="output-container rounded">
                <div className="social-post-output p-3">

                  <div className="">
                    <p
                      className="mb-3 p-2 media-post-content rounded"
                    >
                      {message?.message}
                    </p>
                    <h5>🍊 Healthy Diet Idea:</h5>
                    <ul>
                      {message?.dietIdea?.map((idea, i) => (
                        <li key={i}>{idea}</li>
                      ))}
                    </ul>
                    <h5>💪 Exercise Idea:</h5>
                    <ul>
                      {message?.exerciseIdea?.map((idea, j) => (
                        <li key={j}>{idea}</li>
                      ))}
                    </ul>

                    <div className="d-flex justify-content-end gap-2 mt-3">
                      <Button
                        className="border dark-green btn-secondary"
                        onClick={() => handleCopyToClipboard(message)}
                      >
                        {copied.includes(message?.id) ? (
                          <>
                            <MdOutlineDoneAll />{" "}
                            <span className="ps-1">Copied</span>
                          </>
                        ) : (
                          <>
                            <IoCopyOutline />{" "}
                            <span className="ps-1">Copy</span>
                          </>
                        )}
                      </Button>
                      <Button
                        className="border dark-green btn-secondary"
                        onClick={() => saveGeneratedPost(message)}
                      >
                        <>
                          <MdSave />{" "}
                          <span className="ps-1">Save</span>
                        </>
                      </Button>
                    </div>
                  </div>
                </div>
              </div>
            </>
          )
        }
      </Card >
    </Container >
  );
}

export default SocialMediaIdeaUI;

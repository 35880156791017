import React from 'react'
import { Card, Col, Container, Row, Table } from 'react-bootstrap';
import { FaUserTie } from 'react-icons/fa';
import { GiMeal } from 'react-icons/gi';

const AdminDashboard = () => {
    return (
        <Container>
            <Row>
                <Col xl={6} lg={6} md={12}>
                    <Card>
                        <Card.Body className="cardBody">
                            <div className="content">
                                <p className="text-muted">Total Trainers</p>
                                <h5>12,208</h5>
                            </div>
                            <div className="icon">
                                <FaUserTie />
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
                <Col xl={6} lg={6} md={12}>
                    <Card>
                        <Card.Body className="cardBody">
                            <div className="content">
                                <p className="text-muted">Total Meal Plan Genetare</p>
                                <h5>10,208</h5>
                            </div>
                            <div className="icon">
                                <GiMeal />
                            </div>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>

            <Row>
                <Col>
                    <Table striped bordered hover>
                        <thead>
                            <tr>
                                <th>Trainer Name</th>
                                <th>Using Pricing Plan</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>Abu Said</td>
                                <td colSpan={2}>Free Plan</td>
                            </tr>
                            <tr>
                                <td>Tuhin Khan</td>
                                <td colSpan={2}>Standrad Plan</td>
                            </tr>
                            <tr>
                                <td>Md Kawsar Mia</td>
                                <td colSpan={2}>Advance Plan</td>
                            </tr>
                        </tbody>
                    </Table>
                </Col>
            </Row>
        </Container>
    )
}

export default AdminDashboard;

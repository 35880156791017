import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import { FaUserTie } from "react-icons/fa";
import { GiMeal } from "react-icons/gi";
import { MdOutlineSocialDistance } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import useTrainerPlan from "../../Hooks/usePlan";
import { axiosSecure, getAuthorizationHeader } from "../../api/axios";
import ActivePlanCard from "./ActivePlanCard";
const TRAINER_DASHBOARD_URL = "/trainer/dashboard";

const TrainerDashboard = ({ logedinUser }) => {
  const [activePlan, setActivePlan] = useState({});
  const [trainerData, setTrainerData] = useState([]);
  const { remaining } = useTrainerPlan();
  const navigate = useNavigate();

  function calculatePercentage(total, number) {
    const used = total - number;
    const percentage = (parseInt(used) / parseInt(total)) * 100;
    return Math.round(percentage);
  }


  const fetchTrainerData = async () => {
    try {
      const response = await axiosSecure.get(TRAINER_DASHBOARD_URL, {
        headers: { Authorization: getAuthorizationHeader() },
      });
      setTrainerData(response?.data);
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  };


  useEffect(() => {
    const fetchTrainerSubscriptionData = async () => {
      if (logedinUser && logedinUser.userType === 2) {
        try {
          const response = await axiosSecure.get(
            `/user-plan/${logedinUser.activePlanId}`,
            {
              headers: { Authorization: getAuthorizationHeader() },
            }
          );
          const activePlan = response?.data?.data;
          setActivePlan(activePlan);
        } catch (err) {
          toast.error(err?.response?.data?.message);
        }
      }
    };

    fetchTrainerSubscriptionData();
    fetchTrainerData();
  }, [logedinUser]);


  return (
    <Container className="trainer-dashboard">
      <Row>
        <Col xl={4} lg={6} md={12}>
          <Card>
            <Card.Body className="cardBody">
              <div className="content">
                <p className="text-muted">Total Client</p>
                <h5>{trainerData?.totalClient}</h5>
              </div>
              <div className="icon">
                <FaUserTie />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={4} lg={6} md={12}>
          <Card>
            <Card.Body className="cardBody">
              <div className="content">
                <p className="text-muted">Total Meal Plan Genetare</p>
                <h5>{trainerData?.totalMealPlan}</h5>
              </div>
              <div className="icon">
                <GiMeal />
              </div>
            </Card.Body>
          </Card>
        </Col>
        <Col xl={4} lg={6} md={12}>
          <Card>
            <Card.Body className="cardBody">
              <div className="content">
                <p className="text-muted">Social Media Posts Generated</p>
                <h5>{trainerData?.totalSocialMediaPost}</h5>
              </div>
              <div className="icon">
                <MdOutlineSocialDistance />
              </div>
            </Card.Body>
          </Card>
        </Col>
      </Row>
      <div className="py-4 row gx-5 g-xl-8">
        <div className="col-md-6 col-xl-5 plan-area">
          <h5 className="my-4 title">Active Plan</h5>
          <ActivePlanCard active={true} plan={activePlan} />
        </div>
        <div className="col-md-6 col-xl-7 plan-area">
          <h5 className="my-4 title">Remaining Plans</h5>
          <div className="row g-2">
            <div className="col-12">
              <div className="card p-3">
                <p className="fw-bold fs-4 mt-0" style={{ color: "#3BC177" }}>
                  Client Create
                </p>
                <p className="fw-medium text-muted mt-0 fs-7">
                  {`${remaining?.clientRemaining}/${activePlan?.numberOfClient}
                   Client Remaining`}
                </p>
                <div
                  className="progress h-7px"
                  style={{ backgroundColor: "#9CE6BE" }}
                >
                  <div
                    className="progress-bar"
                    style={{
                      width: `${calculatePercentage(activePlan?.numberOfClient, remaining?.clientRemaining)}%`,
                      backgroundColor: "#3BC177"
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="card p-3">
                <p className="fw-bold fs-4 mt-0" style={{ color: "#FFC700" }}>
                  Meal Generate
                </p>
                <p className="fw-medium text-muted mt-0 fs-7">
                  {`${remaining?.mealPlanRemaining}/${activePlan?.numberOfmealPlan}
                   Meal Remaining`}
                </p>
                <div
                  className="progress h-7px mt-7"
                  style={{ backgroundColor: "#FFE06E" }}
                >
                  <div
                    className="progress-bar"
                    style={{
                      width: `${calculatePercentage(activePlan?.numberOfmealPlan, remaining?.mealPlanRemaining)}%`,
                      backgroundColor: "#FFC700"
                    }}
                  />
                </div>
              </div>
            </div>

            <div className="col-12">
              <div className="card p-3">
                <p className="fw-bold fs-4 mt-0" style={{ color: "#009EF7" }}>
                  Social Media Posts
                </p>
                <p className="fw-medium text-muted mt-0 fs-7">
                  {`${remaining?.socialMediaIdeaRemaining}/${activePlan?.numberOfSocialMediaIdea}
                  Social Media Posts Remaining`}
                </p>
                <div
                  className="progress h-7px mt-7"
                  style={{ backgroundColor: "#78CCFB" }}
                >
                  <div
                    className="progress-bar"
                    style={{
                      width: `${calculatePercentage(activePlan?.numberOfSocialMediaIdea, remaining?.socialMediaIdeaRemaining)}%`,
                      backgroundColor: "#009EF7"
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {parseInt(activePlan?.price) === 0 &&
        <div
          className="p-3 p-md-5 w-100 upgrade-card d-md-flex justify-content-between align-items-center"
        >
          <div className="">
            <h3 className="upgrade-text">
              You are using the free plan.
            </h3>
            <h3 className="upgrade-text mt-2">
              Upgrade your plan to get more credits.
            </h3>
          </div>
          <div className="mx-md-5 d-flex justify-content-center align-content-end">
            <Button
              variant="success"
              onClick={() => navigate("/pricing")}
              className='btn upgrade-btn px-4 my-3'>
              Upgrade Now
            </Button>
          </div>
        </div>
      }
    </Container >
  );
};

export default TrainerDashboard;

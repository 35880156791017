// @flow strict

import * as React from "react";
import { Button, Card, Container, Form, Spinner } from "react-bootstrap";
import MealPlanOutput from "./MealPlanOutput";

function MealPlaningUI(props) {
  const {
    handleGenerateIdea,
    handleOnchange,
    handleCopyToClipboard,
    firstFourDaysPlan,
    loading,
    showTooltip,
    clients,
    inputData,
    selectedClient,
    handleMailSend,
    saveGeneratedMeal,
    loadingSend,
    isSavedFirst,
    isSavedSecond,
    andThreeDaysPlan,
    currentGeneratingFor,
  } = props;

  return (
    <Container className="d-flex justify-content-center my-4">
      <Card className="social-media-idea-generator shadow border-0 rounded">
        <h3 className="text-center my-4">Meal Plan Generator</h3>
        <div className="social-media-idea-generator-body">
          <Form onSubmit={handleGenerateIdea}>
            <Form.Group className="mb-3" controlId="formMonthPicker">
              <Form.Label>Select a Client</Form.Label>
              <Form.Control
                as="select"
                value={inputData?.client}
                name="client"
                onChange={handleOnchange}
              >
                <option value="">Select a Client...</option>

                {clients?.length > 0 &&
                  clients.map((client, index) => (
                    <option key={index} value={client._id}>
                      {client.firstName + " " + client.lastName}
                    </option>
                  ))}
              </Form.Control>
            </Form.Group>
            {selectedClient && (
              <>
                <Form.Group className="mb-3">
                  <Form.Label>Age</Form.Label>
                  <Form.Control value={selectedClient?.age} disabled />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Weight</Form.Label>
                  <Form.Control value={selectedClient?.weight} disabled />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Height</Form.Label>
                  <Form.Control value={selectedClient?.height} disabled />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Allergies Food</Form.Label>
                  <Form.Control value={selectedClient?.allergies} disabled />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label>Goal</Form.Label>
                  <Form.Control value={selectedClient?.goal} disabled />
                </Form.Group>
              </>
            )}
            <div className="d-flex justify-content-center mt-4">
              <Button
                type="submit"
                className="btn btn-primary"
                disabled={loading}
              >
                {loading ? (
                  <>
                    <Spinner
                      as="span"
                      animation="grow"
                      size="sm"
                      role="status"
                      aria-hidden="true"
                    />
                    <span className="ps-1">
                      {`We're cooking up your custom meal plan for ${
                        currentGeneratingFor === "First"
                          ? "First Four days"
                          : "Last Three days"
                      }. It'll be ready soon.`}
                    </span>
                  </>
                ) : (
                  <span>Generate</span>
                )}
              </Button>
            </div>
          </Form>
        </div>
        {firstFourDaysPlan && (
          <MealPlanOutput
            handleCopyToClipboard={handleCopyToClipboard}
            outputData={firstFourDaysPlan}
            handleMailSend={handleMailSend}
            generatingFor={"First"}
            showTooltip={showTooltip}
            saveGeneratedMeal={saveGeneratedMeal}
            loadingSend={loadingSend}
            isSaved={isSavedFirst}
          />
        )}
        {andThreeDaysPlan && (
          <MealPlanOutput
            handleCopyToClipboard={handleCopyToClipboard}
            outputData={andThreeDaysPlan}
            handleMailSend={handleMailSend}
            generatingFor={"Second"}
            showTooltip={showTooltip}
            saveGeneratedMeal={saveGeneratedMeal}
            loadingSend={loadingSend}
            isSaved={isSavedSecond}
          />
        )}
      </Card>
    </Container>
  );
}

export default MealPlaningUI;

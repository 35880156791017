import { useMemo } from "react";
import { Route, Routes } from "react-router-dom";
import {
  AddClient,
  AddUser,
  ChangePassword,
  Dashboard,
  EditClient,
  EditUser,
  ListClient,
  ListUser,
  PageNotFound,
  SubscriptionHistory,
  UserProfile,
  ViewClient,
  ViewUser,
} from "../../Pages";
import { Footer, Header, Sidebar } from "../../component";
import SidebarContextProvider from "../../contexts/SidebarContext";
import { getLogedInUser } from "../Clients/Edit";
import MealPlaning from "../MealPlaning";
import PaymentSucceeded from "../PaymentSucceeded";
import Pricing from "../Pricing";
import SavedMeals from "../SavedMeals";
import SavedPost from "../SavedPost";
import ScheduleMealPlan from "../ScheduleMealPlan";
import SocialMediaIdeaGenerator from "../SocialMediaIdea";
import SubscriptionHistoryView from "../SubscriptionHistory/View";
import AddUserPlan from "../UsersPlan/Add";
import EditUserPlan from "../UsersPlan/Edit";
import ListUserPlan from "../UsersPlan/List";
import ViewUserPlan from "../UsersPlan/View";
import ScheduleMealPlanHistory from "../ScheduleMealPlanHistory";

const superAdminRoute = "super-admin";
const trainerRoute = "trainer";

const Layout = () => {
  const user = getLogedInUser();

  const routeDemoItem = useMemo(() => {
    if (user && user.userType === 1) {
      return superAdminRoute;
    } else if (user && user.userType === 2) {
      return trainerRoute;
    }
    return "others";
  }, [user]);

  return (
    <SidebarContextProvider>
      <main className="d-flex flex-nowrap">
        <Sidebar />
        <div className="w-100 overflow-auto main-wrapper min-vh-100 d-flex flex-column">
          <Header />
          <section>
            <Routes>
              <Route path="user-profile" element={<UserProfile />} />
              <Route path="change-password" element={<ChangePassword />} />

              {routeDemoItem === superAdminRoute && (
                <>
                  <Route index element={<ListUser />} />

                  <Route path="user/add" element={<AddUser />} />
                  <Route path="user/edit/:userId" element={<EditUser />} />
                  <Route path="user/view/:userId" element={<ViewUser />} />
                  <Route path="user" element={<ListUser />} />
                  <Route path="user-plan" element={<ListUserPlan />} />
                  <Route path="user-plan/add" element={<AddUserPlan />} />
                  <Route
                    path="user-plan/edit/:planId"
                    element={<EditUserPlan />}
                  />
                  <Route
                    path="user-plan/view/:planId"
                    element={<ViewUserPlan />}
                  />
                </>
              )}

              {routeDemoItem === trainerRoute && (
                <>
                  <Route index element={<Dashboard />} />
                  <Route path="schedule-meal-plan" element={<ScheduleMealPlan />} />
                  <Route path="schedule-meal-plan-history" element={<ScheduleMealPlanHistory />} />
                  <Route path="client/add" element={<AddClient />} />
                  <Route
                    path="client/edit/:clientId"
                    element={<EditClient />}
                  />
                  <Route
                    path="client/view/:clientId"
                    element={<ViewClient />}
                  />
                  <Route path="client" element={<ListClient />} />

                  <Route
                    path="socialmedia-idea"
                    element={<SocialMediaIdeaGenerator />}
                  />
                  <Route path="meal-planing" element={<MealPlaning />} />
                  <Route path="pricing" element={<Pricing />} />
                  <Route path="subscription-history" element={<SubscriptionHistory />} />
                  <Route path="subscription-history/view/:orderId" element={<SubscriptionHistoryView />} />
                  <Route
                    path="payment-success"
                    element={<PaymentSucceeded />}
                  />

                  <Route path="saved-posts" element={<SavedPost />} />
                  <Route
                    path="saved-meals/:clientId"
                    element={<SavedMeals />}
                  />
                </>
              )}

              <Route path="/*" element={<PageNotFound />} />
            </Routes>
          </section>
          <Footer />
        </div>
      </main>
    </SidebarContextProvider>
  );
};

// const Layout = () => {
//   const location = useLocation();
//   const { role } = getUserDetails();
//   return isLoggedIn() ? (
//       <SidebarContextProvider>
//         <main className="d-flex flex-nowrap">
//           <Sidebar />
//           <div className="w-100 overflow-auto main-wrapper min-vh-100 d-flex flex-column">
//             <Header />
//             <section>
//               <Outlet />
//             </section>
//             <Footer />
//           </div>
//         </main>
//       </SidebarContextProvider>
//   ) : (
//     <Navigate to="/login" state={{ from: location }} replace />
//   );
// };


export default Layout;

import React, { useContext, useEffect, useMemo } from "react";
import { Offcanvas } from "react-bootstrap";
import { useWindowSize } from "react-use";
import { getLogedInUser } from "../../../Pages/Clients/Edit";
import logo from "../../../assests/images/craftedMealLogo.png";
import { SidebarContext } from "../../../contexts/SidebarContext";
import "./Sidebar.scss";
import SidebarNav from "./SidebarNav";

const superAdminNav = "super-admin";
const trainerNav = "trainer";

const Sidebar = () => {
  const { activeMenu, setActiveMenu } = useContext(SidebarContext);
  const loggedInUser = getLogedInUser();
  const { width } = useWindowSize();

  const navigationItem = useMemo(() => {
    if (loggedInUser?.userType === 1) {
      return superAdminNav;
    }
    return trainerNav;
  }, [loggedInUser?.userType]);

  useEffect(() => {
    if (width < 992) {
      setActiveMenu(false);
    }
  }, [setActiveMenu, width]);

  if (width < 576) {
    return (
      <Offcanvas
        className="sidebar-bg"
        show={activeMenu}
        onHide={() => setActiveMenu(false)}
      >
        <Offcanvas.Header
          className="dark-green sidebar-inner-ers"
          closeVariant="white"
          closeButton
        >
          <a
            href="/"
            className="d-flex align-items-center mb-md-0 brand-logo dark-green text-decoration-none"
          >
            <img alt="Crafted Meals" src={logo} />
          </a>
          <hr className="dark-green" />
        </Offcanvas.Header>
        <div className="transition d-flex sidebar-bg">
          <div className="d-flex flex-column flex-shrink-0 px-3 dark-green w-100">
            <SidebarNav navigationItem={navigationItem} />
          </div>
        </div>
      </Offcanvas>
    );
  }

  return (
    <div
      className={
        !activeMenu
          ? "sidebar d-flex sidebar-bg hide"
          : "sidebar d-flex sidebar-bg"
      }
    >
      <div className="sidebar-inner transition d-flex sidebar-bg px-3">
        <div className=" d-flex flex-column flex-shrink-0 dark-green w-100">
          <a
            href="/"
            className="d-flex brand-logo align-items-center pt-3 pb-3 mb-md-0 
            dark-green text-decoration-none"
          >
            <img alt="Sorted Rack" src={logo} className="" />
          </a>

          <hr className="dark-green mt-0" />

          <SidebarNav navigationItem={navigationItem} />
        </div>
      </div>
    </div>
  );
};

export default Sidebar;

// @flow strict

import * as React from "react";
import { Button, OverlayTrigger, Spinner, Tooltip } from "react-bootstrap";
import { MdOutgoingMail, MdOutlineDoneAll, MdSave } from "react-icons/md";

function MealPlanOutput({
  handleCopyToClipboard,
  handleMailSend,
  showTooltip,
  outputData,
  saveGeneratedMeal,
  loadingSend,
  isSaved,
  generatingFor,
}) {

  return (
    <>
      <div className="border-bottom my-3"></div>

      <div className="output-container p-3 rounded">
        <div className="d-flex justify-content-end gap-2 mb-3 ">
          <Button
            variant="light"
            onClick={() => handleMailSend(outputData)}
            className="border dark-green"
            disabled={loadingSend}
          >
            {loadingSend ? (
              <>
                <Spinner
                  as="span"
                  animation="grow"
                  size="sm"
                  role="status"
                  aria-hidden="true"
                />
                <span className="ps-1">
                  Sending...
                </span>
              </>
            ) : (
              <>
                <MdOutgoingMail />
                <span className="ps-1">Send Mail</span>
              </>
            )}
          </Button>
          <Button
            className="border dark-green btn-secondary"
            onClick={() => saveGeneratedMeal(outputData, generatingFor)}
            disabled={isSaved}
          >
            {!isSaved ?
              <>
                <MdSave />{" "}
                <span className="ps-1">Save</span>
              </>
              :
              <>
                <MdOutlineDoneAll />{" "}
                <span className="ps-1">Saved</span>
              </>
            }
          </Button>
          <OverlayTrigger
            placement="top"
            show={showTooltip}
            overlay={<Tooltip id="tooltip">{"Text copied!"}</Tooltip>}
          >
            <Button
              className="border dark-green btn-secondary"
              onClick={() => handleCopyToClipboard(outputData)}
            >
              Copy
            </Button>
          </OverlayTrigger>
        </div>
        <div
          className="social-post-output mt-5"
          // dangerouslySetInnerHTML={{ __html: outputData }}
        >
          <h3 className="mb-3">{`Your ${
            generatingFor === "First" ? "First Four days" : "Last Three days"
          } Meal Plans:`}</h3>
          <p className="fst-italic">{outputData?.TDEE_details}</p>
          <div className="">
            {outputData?.plans?.length > 0 &&
              outputData?.plans?.map((plan, index) => (
                <div key={index} className="mb-5">
                  <h5 className="">{plan?.title}</h5>
                  <ul>
                    <li className="mb-2">
                      <span style={{ fontWeight: 500 }}>Breakfast: </span>
                      {plan?.breakfast}
                    </li>
                    <li className="my-2">
                      <span style={{ fontWeight: 500 }}>Snack: </span>
                      {plan?.snack}
                    </li>
                    <li className="my-2">
                      <span style={{ fontWeight: 500 }}>Lunch: </span>
                      {plan?.lunch}
                    </li>
                    <li className="my-2">
                      <span style={{ fontWeight: 500 }}>Snack: </span>
                      {plan?.afternoon_snack}
                    </li>
                    <li className="my-2">
                      <span style={{ fontWeight: 500 }}>Dinner: </span>
                      {plan?.dinner}
                    </li>
                    <li className="my-2">
                      <span style={{ fontWeight: 500 }}>Snack: </span>
                      {plan?.snack_2}
                    </li>
                  </ul>

                  <p className="my-2">
                    <span style={{ fontWeight: 500 }}>Total Calories: </span>
                    {plan?.total_calories}
                  </p>
                  <p className="my-2">
                    <span style={{ fontWeight: 500 }}>Fat: </span>
                    {plan?.fat}
                  </p>
                  <p className="my-2">
                    <span style={{ fontWeight: 500 }}>Carbs: </span>
                    {plan?.carbs}
                  </p>
                  <p className="my-2">
                    <span style={{ fontWeight: 500 }}>Protein: </span>
                    {plan?.protein}
                  </p>
                </div>
              ))}
          </div>
          <p style={{ fontWeight: 500 }}>{outputData?.summary}</p>
        </div>
      </div>
    </>
  );
}

export default MealPlanOutput;

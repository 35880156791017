import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { logout } from "../../../service";
import { SidebarContext } from "../../../contexts/SidebarContext";
import { IoMenu } from "react-icons/io5";
import { CgProfile } from "react-icons/cg";
// import "bootstrap/dist/css/bootstrap.min.css";

const Header = () => {
  const { activeMenu, setActiveMenu } = useContext(SidebarContext);
  const navigate = useNavigate();

  const handleLogout = () => {
    navigate("/login", { replace: true });
    logout();
  };

  return (
    <>
      <nav
        className="navbar navbar-expand navbar-dark navbar-bg sticky-top"
        aria-label="Second navbar example"
      >
        <div className="container-fluid">
          <IoMenu
            className="burger-menu"
            onClick={() => setActiveMenu(!activeMenu)}
          />
          <div className="collapse navbar-collapse" id="navbarsExample02">
            <form role="search" className="ms-auto pe-2">
              {/* <input className="form-control" type="search" placeholder="Search" aria-label="Search" /> */}
            </form>
            <span className="profile-image">
              {/* <Link to="/user-profile">RH</Link> */}
              <Link to="/user-profile"><CgProfile size={30} color="gray" /></Link>
            </span>
            {/* <div ref={ref}>
              <Dropdown>
                <Dropdown.Toggle variant="text" id="dropdown-basic" className="dropdown-toggle-no-caret">
                  <span className="notificationIcon" onClick={handleClick}>
                    <IoMdNotificationsOutline size={30} />
                    <span className="number">
                      0
                    </span>
                  </span>
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <div className="header">
                    Notification

                    <span>10</span>
                  </div>

                  <Dropdown.Item href="#/action-1">Action</Dropdown.Item>
                  <Dropdown.Item href="#/action-2">Another action</Dropdown.Item>
                  <Dropdown.Item href="#/action-3">Something else</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>


            </div> */}
            <button
              onClick={handleLogout}
              type="button"
              className="btn outlinde-button-theme"
            >
              Logout
            </button>
          </div>
        </div>
      </nav>
    </>
  );
};

export default Header;

// @flow strict
import React, { useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { FiCheckCircle } from "react-icons/fi";
import { convertDate, getLogedInUser } from "../../Utility/utility";
import SubscriptionCancleModal from "../../component/Pricing/SubscriptionCancleModal";

const checkTimeExpired = (time) => {
  const currentTime = new Date();
  const timeExpired = new Date(time);
  return currentTime > timeExpired;
};

function ActivePlanCard({ plan, active }) {
  const [openModal, setOpenModal] = useState(false);
  const handleCloseModal = () => setOpenModal(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const logedinUser = getLogedInUser();

  return (
    <Card className="shadow p-0  active-price-card pricing-card">
      <div className="price-card-main  p-3">
        <p className="price-title">{plan?.planName}</p>
        <p className="price">
          <span className="price-amount">{plan?.price}</span>
          <span className="price-currency ps-2">$</span>
        </p>
        <p className="price-duration">Per Month</p>
        {active && (
          <>
            {parseInt(plan?.price) === 0 ? (
              checkTimeExpired(logedinUser?.subscriptionExpiration) ? (
                <p className="expire-message">Your plan has expired!</p>
              ) : (
                <Button
                  variant="success"
                  className="btn btn-regular px-4 my-3"
                  disabled
                >
                  Activated
                </Button>
              )
            ) : logedinUser?.subscriptionId ? (
              checkTimeExpired(logedinUser?.subscriptionExpiration) ? (
                <p className="expire-message">Your plan has expired!</p>
              ) : (
                <Button
                  variant="warning"
                  className="btn px-4 my-3"
                  onClick={() => handleOpenModal()}
                  disabled={!logedinUser?.subscriptionId}
                >
                  Unsubscribe
                </Button>
              )
            ) : (
              <>
                {checkTimeExpired(logedinUser?.subscriptionExpiration) ? (
                  <p className="expire-message">Your plan has expired!</p>
                ) : (
                  <p className="expire-message">
                    This plan will be expired on{" "}
                    {convertDate(logedinUser?.subscriptionExpiration)}
                  </p>
                )}
              </>
            )}
            <FiCheckCircle className="active-icon" />
          </>
        )}
      </div>
      <div className="price-card-details  p-4">
        <p className="price-description">
          <BsFillCheckCircleFill className="price-description-icon" />
          <span>Up to {plan?.numberOfClient} Clients.</span>
        </p>
        <p className="price-description">
          <BsFillCheckCircleFill className="price-description-icon" />
          <span>
            Up to {plan?.numberOfSocialMediaIdea} Social Media Post Generate.
          </span>
        </p>
        <p className="price-description">
          <BsFillCheckCircleFill className="price-description-icon" />
          <span>Up to {plan?.numberOfmealPlan} Meal Plans Generate.</span>
        </p>
      </div>

      <Modal
        scrollable={true}
        show={openModal}
        onHide={handleCloseModal}
        centered
      >
        <SubscriptionCancleModal handleCloseModal={handleCloseModal} />
      </Modal>
    </Card>
  );
}

export default ActivePlanCard;

import { Field, Formik } from "formik";
import React from "react";
import { FormControl } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";
import "./Edit.scss";

const validationSchema = Yup.object({
  planName: Yup.string().required("Plan Name is Required"),
  price: Yup
    .number()
    .required('Price is required')
    .positive('Price must be a positive number')
    .min(0, 'Price must be greater than or equal to 0'),
  numberOfClient: Yup.number().required("Number Of Client is required"),
  numberOfmealPlan: Yup.number().required("Number Of Meal Plan is required"),
  numberOfSocialMediaIdea: Yup.number().required(
    "Number Of Social Media Idea is required"
  ),
  stripePriceKey: Yup.string().when('price', {
    is: (price) => parseInt(price) !== 0,
    then: Yup.string().required("Stripe Price key is required"),
    otherwise: Yup.string(),
  }),
});

const EditUserForm = ({ defaultValue, handleEditPlan }) => {
  const navigate = useNavigate();

  return (
    <div className="flex-grow-1">
      {defaultValue?._id ? (
        <Formik
          initialValues={defaultValue}
          validationSchema={validationSchema}
          onSubmit={handleEditPlan}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleSubmit,
            isSubmitting,
            /* and other goodies */
          }) => (
            <Form onSubmit={handleSubmit}>
              <Container className="add-user-page d-flex flex-column justify-content-center">
                <div className="d-flex align-items-center justify-content-between ">
                  <h2 className=" mb-4 ">Edit User Plan</h2>
                  <Button
                    variant="outline-secondary"
                    size="sm"
                    className="mb-4"
                    onClick={() => navigate(-1)}
                  >
                    <BiArrowBack /> Back
                  </Button>
                </div>
                {/* <h5 className="fw-bold mb-3">User Details</h5> */}
                <Row className="gy-2">
                  <Col md={6} lg={6} xl={6}>
                    <FloatingLabel
                      controlId="planName"
                      label="Plan Name"
                      className="mb-3"
                    >
                      <Field
                        as={FormControl}
                        type="text"
                        name="planName"
                        placeholder="Plan Name"
                        className={`form-control ${touched.planName && errors.planName
                          ? "is-invalid"
                          : ""
                          }`}
                      />
                      <div className="invalid-feedback">
                        {touched.planName && errors.planName
                          ? errors.planName
                          : null}
                      </div>
                    </FloatingLabel>
                  </Col>

                  <Col md={6} lg={6} xl={6}>
                    <FloatingLabel
                      controlId="price"
                      label="Price"
                      className="mb-3"
                    >
                      <Field
                        as={FormControl}
                        type="number"
                        name="price"
                        placeholder="price"
                        className={`form-control ${touched.price && errors.price ? "is-invalid" : ""
                          }`}
                      />
                      <div className="invalid-feedback">
                        {touched.price && errors.price ? errors.price : null}
                      </div>
                    </FloatingLabel>
                  </Col>

                  <Col md={6} lg={6} xl={6}>
                    <FloatingLabel
                      controlId="numberOfClient"
                      label="Number Of Client"
                      className="mb-3"
                    >
                      <Field
                        as={FormControl}
                        type="number"
                        name="numberOfClient"
                        placeholder="Number Of Client"
                        className={`form-control ${touched.numberOfClient && errors.numberOfClient
                          ? "is-invalid"
                          : ""
                          }`}
                      />
                      <div className="invalid-feedback">
                        {touched.numberOfClient && errors.numberOfClient
                          ? errors.numberOfClient
                          : null}
                      </div>
                    </FloatingLabel>
                  </Col>
                  <Col md={6} lg={6} xl={6}>
                    <FloatingLabel
                      controlId="numberOfmealPlan"
                      label="Number Of Meal Plan"
                      className="mb-3"
                    >
                      <Field
                        as={FormControl}
                        type="number"
                        name="numberOfmealPlan"
                        placeholder="NumberOfmealPlan"
                        className={`form-control ${touched.numberOfmealPlan && errors.numberOfmealPlan
                          ? "is-invalid"
                          : ""
                          }`}
                      />
                      <div className="invalid-feedback">
                        {touched.numberOfmealPlan && errors.numberOfmealPlan
                          ? errors.numberOfmealPlan
                          : null}
                      </div>
                    </FloatingLabel>
                  </Col>
                  <Col md={6} lg={6} xl={6}>
                    <FloatingLabel
                      controlId="numberOfSocialMediaIdea"
                      label="Number Of Social Media Idea"
                      className="mb-3"
                    >
                      <Field
                        as={FormControl}
                        type="number"
                        name="numberOfSocialMediaIdea"
                        placeholder="NumberOfSocialMediaIdea"
                        className={`form-control ${touched.numberOfSocialMediaIdea &&
                          errors.numberOfSocialMediaIdea
                          ? "is-invalid"
                          : ""
                          }`}
                      />
                      <div className="invalid-feedback">
                        {touched.numberOfSocialMediaIdea &&
                          errors.numberOfSocialMediaIdea
                          ? errors.numberOfSocialMediaIdea
                          : null}
                      </div>
                    </FloatingLabel>
                  </Col>
                  <Col md={6} lg={6} xl={6}>
                    <FloatingLabel
                      controlId="stripePriceKey"
                      label="Stripe Price Key"
                      className="mb-3"
                    >
                      <Field
                        as={FormControl}
                        name="stripePriceKey"
                        placeholder="stripe Price Key"
                        className={`form-control ${touched.stripePriceKey &&
                          errors.stripePriceKey
                          ? "is-invalid"
                          : ""
                          }`}
                      />
                      <div className="invalid-feedback">
                        {touched.stripePriceKey &&
                          errors.stripePriceKey
                          ? errors.stripePriceKey
                          : null}
                      </div>
                    </FloatingLabel>
                  </Col>
                </Row>
                <Row></Row>
                <Col md={12} lg={12} xl={12} className="mt-4 mb-4 ">
                  <Button type="submit" disabled={isSubmitting}>
                    UPDATE USER PLAN
                  </Button>
                </Col>
              </Container>
            </Form>
          )}
        </Formik>
      ) : null}
    </div>
  );
};

export default EditUserForm;

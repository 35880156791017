import { Button } from "react-bootstrap";
import React from "react";

const SubscriptionInfo = ({ plan, onHide }) => {
  return (
    <div className="subscription-info">
      <div className="header">
        <h3 className="mb-0">{`Subscribe to  ${plan?.planName}`}</h3>
      </div>
      <div className="description text-center">
        <p className="mb-0">
          Upon purchase of this plan, you will be automatically unsubscribed
          from your current plan.
        </p>
      </div>

      <div className="d-flex justify-content-center align-items-center gap-3 btnSection">
        <Button variant="secondary" className="btn px-4" onClick={onHide}>
          Cancel
        </Button>
        <form
          action={`${process.env.REACT_APP_BASE_URL}/create-checkout-session`}
          method="POST"
        >
          <input type="hidden" name="priceId" value={plan?.stripePriceKey} />
          <input type="hidden" name="planId" value={plan?._id} />
          <Button
            variant="success"
            className="btn btn-regular px-4"
            type="submit"
          >
            Purchase
          </Button>
        </form>
      </div>
    </div>
  );
};

export default SubscriptionInfo;

import React, { useEffect, useState } from "react";
import { Button, Card, Col, Container, ListGroup, Row } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { convertDate, formatStringWithCount } from "../../../Utility/utility";
import { axiosSecure, getAuthorizationHeader } from "../../../api/axios";
import "./View.scss";

function getGender(gender) {
  if (Number(gender) === 1) return "Male";
  else if (Number(gender) === 2) return "Female";
  return "Others";
}

function getFormattedGoal(goal) {
  return goal.includes("-") ? goal.split("-").join(" ").toUpperCase() : goal;
}

const ViewClient = () => {
  let { clientId } = useParams();
  const navigate = useNavigate();
  const [error, setError] = useState("");
  const [loading, setLoading] = useState(false);
  const [client, setClient] = useState(null);

  useEffect(() => {
    const controller = new AbortController();
    const fetchClientData = async () => {
      try {
        setLoading(true);
        const response = await axiosSecure.get(`/trainer/client/${clientId}`, {
          headers: { Authorization: getAuthorizationHeader() },
        });
        setClient(response?.data?.data);
      } catch (err) {
        toast.error(err?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    };
    fetchClientData();

    return () => controller && controller.abort();
  }, [clientId]);

  if (loading) {
    return (
      <Container>
        <Row>
          <Col>
            <div className="user_card_wrapper m-auto">
              <div className="d-flex justify-content-center align-items-center h-100">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  if (error) {
    return (
      <Container>
        <Row>
          <Col>
            <div className="user_card_wrapper m-auto">
              <Card>
                <Card.Header>Error</Card.Header>
                <Card.Body>
                  <Card.Title className="text-danger">{error}</Card.Title>
                </Card.Body>
              </Card>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }
  return (
    <Container>
      <Row>
        <Col>
          <div className="user_card_wrapper m-auto">
            <Card className="card">
              <Card.Header className="d-flex align-items-center justify-content-between">
                <h4>Client Details</h4>
                <Button
                  variant="outline-secondary"
                  onClick={() => navigate(-1)}
                >
                  Back
                </Button>
              </Card.Header>
              <Card.Body>
                <Card.Title>
                  {client ? `${client?.firstName} ${client?.lastName}` : ""}
                </Card.Title>
              </Card.Body>
              <ListGroup className="list-group-flush">
                <ListGroup.Item>
                  <strong>Email: </strong>
                  {client ? `${client?.email}` : ""}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Age: </strong>
                  {client ? `${client?.age}` : ""}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Height: </strong>
                  {client ? `${client?.height}` : ""}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Weight: </strong>
                  {client ? `${client?.weight}` : ""}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Gender: </strong>
                  {client ? getGender(client?.gender) : ""}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Goal: </strong>
                  {client ? getFormattedGoal(client?.goal) : ""}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Food Don't Like: </strong>
                  {client ? `${client?.foodDontLike}` : ""}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Preferred Food: </strong>
                  {client ? `${client?.preferenceFood}` : ""}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Allergies: </strong>
                  {client ? `${client?.allergies}` : ""}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Activity Level: </strong>
                  {client ? `${client?.activity}` : ""}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Generated Meal Count: </strong>
                  {client &&
                    client?.generatedMeals &&
                    formatStringWithCount(client?.generatedMeals, "Time")}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Saved Meal Count: </strong>
                  {client &&
                    client?.generatedMeals &&
                    formatStringWithCount(client?.savedPlansCount, "Time")}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Created At: </strong>
                  {client && client?.createdAt
                    ? convertDate(`${client.createdAt}`)
                    : ""}
                </ListGroup.Item>
                <ListGroup.Item>
                  <strong>Updated At: </strong>
                  {client && client?.updatedAt
                    ? convertDate(`${client.updatedAt}`)
                    : ""}
                </ListGroup.Item>
              </ListGroup>
            </Card>
          </div>
        </Col>
      </Row>
    </Container>
  );
};

export default ViewClient;

import React from 'react';
import { Button, Card, ListGroup } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import './profile.scss';

const UserProfile = () => {
    const { user } = JSON.parse(localStorage.getItem("userDetails"));
    return (
        // style={{ width: '80%', margin: '10% auto' }}
        <div className='profile-card'>
            <Card>
                <Card.Header className="d-flex justify-content-between" style={{ backgroundColor: "#FFF" }}>
                    <h3>User Profile</h3>
                    <Button variant="outline-secondary" as={Link} to="/change-password">Change Password</Button>{' '}
                </Card.Header>
                <ListGroup variant="flush">
                    <ListGroup.Item><b>Name:</b> {user.firstName} {user.lastName}</ListGroup.Item>
                    <ListGroup.Item><b>Email:</b> {user.email}</ListGroup.Item>
                    {user?.userType === 1 ? null : (
                        <>
                            <ListGroup.Item><b>Organization Name:</b> {user.organizationName ?? "---"}</ListGroup.Item>
                            <ListGroup.Item><b>Phone No:</b> {user.phoneNumber ?? "No number found!"}</ListGroup.Item>
                        </>
                    )}

                </ListGroup>
            </Card>
        </div>
    )
}

export default UserProfile;

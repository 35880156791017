// @flow strict

import * as React from "react";
import { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { toast } from "react-toastify";
import useTrainerPlan from "../../Hooks/usePlan";
import { isValidMealPlan } from "../../Utility/utility";
import { axiosSecure, getAuthorizationHeader } from "../../api/axios";
import MealPlaningUI from "../../component/MealPlaning/MealPlaningUI";
import WarningModal from "../../component/MealPlaning/WarningModal";
import { generateBothPrompt } from "./prompt-generator";

function MealPlaning() {
  const { remaining } = useTrainerPlan();
  const [inputData, setInputData] = useState({});
  const [showTooltip, setShowTooltip] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firstFourDaysPlan, setFirstFourDaysPlan] = useState("");
  const [andThreeDaysPlan, setAndThreeDaysPlan] = useState("");
  const [clients, setClients] = useState([]);
  const [currentGeneratingFor, setCurrentGeneratingFor] = useState("First");
  const [selectedClient, setSelectedClient] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [loadingSend, setLoadingSend] = useState(false);
  const [isSavedFirst, setIsSavedFirst] = useState(false);
  const [isSavedSecond, setIsSavedSecond] = useState(false);

  const handleCloseModal = () => setShowModal(false);

  const [savedPlanId, setSavedPlanId] = useState("");

  const handleOnchange = (e) => {
    if (e.target.name === "client") {
      const findClient = clients.find(
        (client) => client._id === e.target.value
      );
      setCurrentGeneratingFor("First");
      setSelectedClient(findClient);
    }
    setInputData((prev) => {
      const temp = JSON.parse(JSON.stringify(prev));
      temp[e.target.name] = e.target.value;
      return temp;
    });
  };

  const handleCopyToClipboard = (outputPlan) => {
    let text = "";

    // Add TDEE details
    text += outputPlan?.TDEE_details + "\n\n";

    // Loop through each plan
    outputPlan?.plans.forEach((plan, index) => {
      // Add plan title
      text += plan.title + " :\n\n";

      // Add meals and snacks
      text += "Breakfast: " + plan.breakfast + "\n";
      text += "Snack: " + plan.snack + "\n";
      text += "Lunch: " + plan.lunch + "\n";
      text += "Afternoon Snack: " + plan.afternoon_snack + "\n";
      text += "Dinner: " + plan.dinner + "\n";
      text += "Snack 2: " + plan.snack_2 + "\n";

      // Add total calories and macronutrients
      text += "Total Calories: " + plan.total_calories + "\n";
      text += "Protein: " + plan.protein + "\n";
      text += "Fat: " + plan.fat + "\n";
      text += "Carbs: " + plan.carbs + "\n";

      // Add line break between plans
      text += "\n";
    });

    // Add summary
    text += outputPlan.summary;

    // Copy text to clipboard
    const el = document.createElement("textarea");
    el.value = text;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);

    setShowTooltip(true);
    setTimeout(() => setShowTooltip(false), 2000);
  };


  const generateFirstPartMealByChatGpt = async (prompt) => {
    setSavedPlanId("");
    const payload = JSON.stringify({
      message: prompt.first,
      generationFor: "mealPlan",
      currentGeneratingFor: "First",
    });

    try {
      const response = await axiosSecure.post(
        "/trainer/generate-meal-plan",
        payload,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );

      const answer = response?.data?.data;
      if (!answer) {
        toast.error("An error occurred. Please try again later.");
        setLoading(false);
        return;
      }
      const jsonResponse = answer
        .slice(answer.indexOf("{"), answer.lastIndexOf("}") + 1)
        .replace(/\n/g, "");


      try {
        const parsedObj = JSON.parse(jsonResponse);
        if (isValidMealPlan(parsedObj)) {
          setFirstFourDaysPlan(parsedObj);
          setCurrentGeneratingFor("Second");
          generateSecondPartMealByChatGpt(prompt);
        } else {
          generateFirstPartMealByChatGpt(prompt);
        }
      } catch (error) {
        generateFirstPartMealByChatGpt(prompt);
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      toast.error(error.response?.data?.message);
    }
  };

  const generateSecondPartMealByChatGpt = async (prompt) => {
    const payload = JSON.stringify({
      message: prompt.second,
      generationFor: "mealPlan",
      currentGeneratingFor: "Second",
    });

    try {
      const response = await axiosSecure.post(
        "/trainer/generate-meal-plan",
        payload,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );

      const answer = response?.data?.data;
      if (!answer) {
        toast.error("An error occurred. Please try again later.");
        setLoading(false);
        return;
      }
      const jsonResponse = answer
        .slice(answer.indexOf("{"), answer.lastIndexOf("}") + 1)
        .replace(/\n/g, "");


      try {
        const parsedObj = JSON.parse(jsonResponse);
        if (isValidMealPlan(parsedObj)) {
          setAndThreeDaysPlan(parsedObj);
          setCurrentGeneratingFor("First");
          setLoading(false);
          await axiosSecure.put(
            "/trainer/client/meal-generated",
            { cid: selectedClient._id },
            {
              headers: { Authorization: getAuthorizationHeader() },
            }
          );
        } else {
          generateSecondPartMealByChatGpt(prompt);
        }
      } catch (error) {
        generateSecondPartMealByChatGpt(prompt);
      }
    } catch (error) {
      setLoading(false);
      toast.error(error.response?.data?.message);
    }
  };

  const handleGenerateMeals = async (event) => {
    event.preventDefault();

    if (remaining?.mealPlanRemaining === 0) {
      setShowModal(true);
      return;
    }

    setLoading(true);

    if (!selectedClient) {
      setLoading(false);
      toast.error("Select a client first.");
      return;
    }

    const generatedPrompts = generateBothPrompt(selectedClient);
    if (generatedPrompts) {
      generateFirstPartMealByChatGpt(generatedPrompts);
    }
  };

  const saveGeneratedMeal = async (post, generatingFor) => {
    const payload = JSON.stringify({
      savedPlanId: savedPlanId ? savedPlanId : undefined,
      TDEE_details: post?.TDEE_details,
      plans: post?.plans,
      summary: post?.summary,
      clientId: selectedClient?._id,
    });

    try {
      const response = await axiosSecure.post(
        "/trainer/meal-plan/add",
        payload,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );
      toast.success(response?.data?.message);
      if (generatingFor === 'First') {
        setIsSavedFirst(true);
      } else {
        setIsSavedSecond(true);
      }
      setSavedPlanId(response?.data?.data?._id || "");
    } catch (err) {
      toast.error(err?.response?.data?.message);
    }
  };

  const handleMailSend = async (outputPlan) => {
    setLoadingSend(true);
    const MAIL_URL = "/trainer/send-mail";

    const payload = JSON.stringify({
      toEmail: selectedClient?.email,
      subject: "Daily meal plan",
      messageBody: outputPlan,
    });

    try {
      const response = await axiosSecure.post(MAIL_URL, payload, {
        headers: { Authorization: getAuthorizationHeader() },
      });

      toast.success(response?.data?.message);
      setLoadingSend(false);
    } catch (err) {
      toast.error(err?.response?.data?.message);
      setLoadingSend(false);
    }
  };

  useEffect(() => {
    const controller = new AbortController();
    const fetchClientData = async () => {
      try {
        // setDataLoader(true);
        const response = await axiosSecure.get(
          `/trainer/clients?page=1&limit=1000`,
          {
            headers: { Authorization: getAuthorizationHeader() },
          }
        );
        setClients(response?.data?.data);
      } catch (err) {
        toast.error(err.response.data.message);
      }
    };
    fetchClientData();

    return () => controller && controller.abort();
  }, []);

  useEffect(() => {
    if (remaining?.mealPlanRemaining === 0) {
      setShowModal(true);
    }
  }, [remaining]);

  return (
    <>
      <MealPlaningUI
        handleOnchange={handleOnchange}
        handleGenerateIdea={handleGenerateMeals}
        loading={loading}
        handleCopyToClipboard={handleCopyToClipboard}
        firstFourDaysPlan={firstFourDaysPlan}
        showTooltip={showTooltip}
        clients={clients}
        andThreeDaysPlan={andThreeDaysPlan}
        currentGeneratingFor={currentGeneratingFor}
        inputData={inputData}
        selectedClient={selectedClient}
        handleMailSend={handleMailSend}
        saveGeneratedMeal={saveGeneratedMeal}
        loadingSend={loadingSend}
        isSavedFirst={isSavedFirst}
        isSavedSecond={isSavedSecond}
      />
      <Modal
        scrollable={true}
        show={showModal}
        onHide={handleCloseModal}
        centered
      >
        <WarningModal handleCloseModal={handleCloseModal} />
      </Modal>
    </>
  );
}

export default MealPlaning;

import React, { useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';

const VerifyResetPasswordToken = () => {
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();

    // Access query parameters
    const query = Object.fromEntries(searchParams);

    useEffect(() => {
        if (query?.resetPassToken) {
            let resetPassToken = query?.resetPassToken;
            localStorage.setItem('resetPassToken', resetPassToken);
            navigate("/reset-password")
        }
    }, [query, navigate]);

    return (
        <div>
            <h4>VerifyResetPasswordToken</h4>
        </div>
    )
};

export default VerifyResetPasswordToken;

import React, { useEffect, useMemo, useState } from "react";
import { Button, Card, Col, Container, Row } from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosSecure, getAuthorizationHeader } from "../../api/axios";
import PaginationComponent from "../../component/Pagination/Pagination";
import SingleSchedule from "./SingleSchedule";

const ScheduleMealPlan = () => {
  const limit = 10;
  const [dataLoader, setDataLoader] = useState(false);
  const [clientList, setClientList] = useState(null);
  const [totalClientCount, setTotalClientCount] = useState(0);
  const [scheduleInput, setScheduleInput] = useState([]);

  let [searchParams, setSearchParams] = useSearchParams();

  const navigate = useNavigate();

  const currentPage = useMemo(() => {
    const page = searchParams.get("page");
    if (page) {
      return +page;
    }
    return 1;
  }, [searchParams]);

  const handlePagination = async (page) => {
    navigate(`?page=${page}`);
  };

  const handleUpdateMultipleSchedules = async () => {
    const clientList = scheduleInput.filter(
      (item) => item.clientId && item.first >= 0 && item.second >= 0
    );
    if (clientList.length === 0) return;

    try {
      await axiosSecure.post(
        "/trainer/schedule-meal-plan/add-multiple",
        {
          clientList: clientList,
        },
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );


      toast.success("Schedule updated successfully!");
    } catch (error) {
      toast.error("Something went wrong!");
    }
  };

  useEffect(() => {
    const fetchClientListAsNeed = async () => {
      try {
        setDataLoader(true);
        const response = await axiosSecure.get(
          `/trainer/shceduled-clients?page=${currentPage}&limit=${limit}`,
          {
            headers: { Authorization: getAuthorizationHeader() },
          }
        );
        setClientList(response?.data?.data);
        setTotalClientCount(Number(response?.data.paginationData?.totalCount));
      } catch (err) {
        toast.error(err?.response?.data?.message);
      } finally {
        setDataLoader(false);
      }
    };
    fetchClientListAsNeed();
  }, [currentPage, limit]);

  if (dataLoader) {
    return (
      <Container className="mt-4">
        <Row>
          <Col>
            <div className="user_card_wrapper m-auto">
              <div className="d-flex justify-content-center align-items-center h-100">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <>
      <Container className="flex-grow-1 mt-2">
        <div className="bg-white p-3 mt-4 rounded-2">
          <h2 className="pt-4 pb-3">Schedule Meal Plan</h2>

          {clientList && clientList?.length > 0 ? (
            <div className="user-table">
              <Table hover responsive>
                <thead>
                  <tr>
                    <th className="text-start">Full Name</th>
                    <th className="text-start">Email</th>
                    <th className="text-start">First Plans</th>
                    <th className="text-start">Second Plans</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody>
                  {clientList.map((item, index) => (
                    <SingleSchedule
                      setClientList={setClientList}
                      setScheduleInput={setScheduleInput}
                      item={item}
                      key={index}
                    />
                  ))}
                </tbody>
              </Table>
              <div className="d-flex justify-content-end">
                <Button
                  size="sm"
                  variant="primary"
                  onClick={handleUpdateMultipleSchedules}
                  disabled={scheduleInput.length === 0}
                >
                  Update Multiple Schedule
                </Button>
              </div>
            </div>
          ) : (
            <Row>
              <Col>
                <Table>
                  <thead>
                    <tr>
                      <th className="text-start">Full Name</th>
                      <th className="text-start">Email</th>
                      <th className="text-start">First Plans</th>
                      <th className="text-start">Second Plans</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                </Table>
                <Card>
                  <Card.Body>
                    <Card.Title className="text-danger text-center">
                      No client data available
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
          <div className="d-flex justify-content-end relative bottom-20 me-3 mt-3 ">
            <PaginationComponent
              total={totalClientCount}
              itemsPerPage={limit}
              currentPage={currentPage}
              onPageChange={(page) => handlePagination(page)}
            />
          </div>
        </div>
      </Container>
    </>
  );
};

export default ScheduleMealPlan;

import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosSecure, getAuthorizationHeader } from "../../../api/axios";
import "./Edit.scss";
import EditUserForm from "./edit-form";

const EditUser = () => {
  const { userId } = useParams();
  const [initialValues, setInitialValues] = useState(null);
  const [loading, setLoading] = useState(false);

  // GET User
  useEffect(() => {
    const controller = new AbortController();
    const fetchUserData = async () => {
      setLoading(true);
      try {
        const response = await axiosSecure.get(`/admin/users/${userId}`, {
          headers: { Authorization: getAuthorizationHeader() },
        });
        setInitialValues(response?.data?.data);
      } catch (err) {
        toast.error(err?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    };
    fetchUserData();

    return () => controller && controller.abort();
  }, [userId]);

  if (loading) {
    return (
      <Container>
        <Row>
          <Col>
            <div className="user_card_wrapper m-auto">
              <div className="d-flex justify-content-center align-items-center h-100">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <div className="flex-grow-1">
      {initialValues && initialValues?._id ? (
        <EditUserForm defaultValue={initialValues} />
      ) : null}
    </div>
  );
};

export default EditUser;

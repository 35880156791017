import { PDFDownloadLink } from "@react-pdf/renderer";
import React from "react";
import { Card, Container } from "react-bootstrap";
import { FaDownload } from "react-icons/fa";
import { convertDate } from "../../../Utility/utility";
import InvoicePdf from "./InvoicePdf";

const OrderUI = ({ subscription }) => {
  const { user } = JSON.parse(localStorage.getItem("userDetails"));

  return (
    <div className="order-content">
      <Container className="my-4">
        <Card className="shadow border-0 rounded p-4 pb-5">
          <h2 className="mb-3 text-center">Plan - {subscription?.plan[0]?.planName}</h2>
          <div className="order-container">
            <div className="row d-flex justify-content-between flex-wrap mb-3 order-header">
              <div className="col">
                <p>
                  <strong>Invoice Id:</strong>
                  <span>{subscription?.invoiceId}</span>
                </p>
              </div>
              <div className="col d-flex justify-content-end">
                <p>
                  <strong>subscription Id:</strong>
                  <span>{subscription?.subscriptionId}</span>
                </p>
              </div>
            </div>
            <div className="order-body">
              <div className="row g-5">
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="custom-card">
                    <b className="mt-2">
                      {subscription?.plan[0]?.numberOfClient}
                    </b>
                    <p className="mt-2">No. Of Client</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="custom-card">
                    <b className="mt-2">
                      {subscription?.plan[0]?.numberOfmealPlan}
                    </b>
                    <p className="mt-2">No. of Meal Plan</p>
                  </div>
                </div>
                <div className="col-12 col-md-6 col-lg-4">
                  <div className="custom-card">
                    <b className="mt-2">
                      {subscription?.plan[0]?.numberOfSocialMediaIdea}
                    </b>
                    <p className="mt-2">No. of Social Media Idea</p>
                  </div>
                </div>
              </div>

              <div className="row d-flex justify-content-between">
                <div className="col-lg-3 col-md-6 col-sm-6 offset-3 ml-auto price-section">
                  <h5 className="header">Total Amount</h5>
                  <p className="d-flex justify-content-between">
                    <strong className="w-4/12">Sub Total</strong>:
                    <span className="w-8/12">
                      ${(subscription?.amountSubtotal / 100).toFixed(2)}
                    </span>
                  </p>
                  <p className="d-flex justify-content-between">
                    <strong className="w-4/12">Total</strong>:
                    <span className="w-8/12">
                      ${(subscription?.amountTotal / 100).toFixed(2)}
                    </span>
                  </p>
                  <p className="d-flex justify-content-between">
                    <strong className="w-4/12">Tax</strong>:
                    <span className="w-8/12">$00.00</span>
                  </p>
                  <p className="d-flex justify-content-between">
                    <strong className="w-4/12">Discount</strong>:
                    <span className="w-8/12">$00.00</span>
                  </p>
                </div>
                <div className="col-lg-6 col-md-6 col-sm-6 ml-auto info-section">
                  <h5 className="header">Order Details</h5>
                  <p className="d-flex justify-content-between">
                    <strong className="w-4/12">Name</strong>
                    <span className="w-8/12">
                      {user?.firstName} {user?.lastName}
                    </span>
                  </p>
                  <p className="d-flex justify-content-between">
                    <strong className="w-4/12">Email</strong>
                    <span className="w-8/12">{user?.email}</span>
                  </p>
                  <p className="d-flex justify-content-between">
                    <strong className="w-4/12">Phone</strong>
                    <span className="w-8/12">{user?.phoneNumber}</span>
                  </p>
                  <p className="d-flex justify-content-between">
                    <strong className="w-4/12">Organization Name</strong>
                    <span className="w-8/12">{user?.organizationName}</span>
                  </p>

                  <p className="d-flex justify-content-between">
                    <strong className="w-4/12">Activate Date</strong>
                    <span className="w-8/12">
                      {convertDate(subscription?.activatedAt)}
                    </span>
                  </p>
                  <p className="d-flex justify-content-between">
                    <strong className="w-4/12">Created Date</strong>
                    <span className="w-8/12">
                      {convertDate(subscription?.plan[0]?.createdAt)}
                    </span>
                  </p>

                  <p className="d-flex justify-content-between">
                    <strong className="w-4/12">Expires Date</strong>
                    <span className="w-8/12">
                      {convertDate(subscription?.expiresAt)}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="d-flex justify-content-end">
            <PDFDownloadLink
              document={<InvoicePdf subscription={subscription} user={user} />}
              fileName={`Crafted_Meals_invoice_${subscription?.invoiceId}.pdf`}
            >
              <>
                <button type="button" className="btn btn-primary btn-sm">
                  <FaDownload />
                  <span className="ml-2"> Download Invoice</span>
                </button>
              </>
            </PDFDownloadLink>
          </div>
        </Card>
      </Container>
    </div>
  );
};

export default OrderUI;

import React from "react";
import { Link } from "react-router-dom";

const Footer = () => {
  return (
    <footer className="footer mt-auto">
      © {new Date().getFullYear()} Crafted Meals, powered by{" "}
      <span style={{ color: "#ef233c" }}>❤</span>{" "}
      <Link
        to="https://buildyourai.consulting/"
        target="_blank"
        rel="noopener noreferrer"
        className="dark-green"
      >
        Build Your AI Consulting{" "}
      </Link>
    </footer>
  );
};

export default Footer;

import React, { useState } from "react";
import { Button, Card, Modal } from "react-bootstrap";
import { BsFillCheckCircleFill } from "react-icons/bs";
import { FiCheckCircle } from "react-icons/fi";
import { getLogedInUser } from "../../Utility/utility";
import SubscriptionCancleModal from "./SubscriptionCancleModal";
import SubscriptionInfo from "./SubscriptionInfo";

const checkTimeExpired = (time) => {
  const currentTime = new Date();
  const timeExpired = new Date(time);
  return currentTime > timeExpired;
};

function PriceCard({ plan, active, handlePurchasePlan }) {
  const [openModal, setOpenModal] = useState(false);
  const [beforePurchaseModal, setBeforePurchaseModal] = useState(false);
  const handleCloseModal = () => setOpenModal(false);
  const handleOpenModal = () => {
    setOpenModal(true);
  };

  const logedinUser = getLogedInUser();

  return (
    <Card
      className={
        active
          ? "shadow p-0  active-price-card pricing-card"
          : "shadow-sm p-0 border-0 pricing-card"
      }
    >
      <div className="price-card-main  p-3">
        <p className="price-title">{plan?.planName}</p>
        <p className="price">
          <span className="price-amount">{plan?.price}</span>
          <span className="price-currency ps-2">$</span>
        </p>
        <p className="price-duration">Per Month</p>
        {active ? (
          <>
            {parseInt(plan.price) === 0 ? (
              checkTimeExpired(logedinUser?.subscriptionExpiration) ? (
                <p className="expire-message">Your plan has expired!</p>
              ) : (
                <Button
                  variant="success"
                  className="btn btn-regular px-4 my-3"
                  disabled
                >
                  Activated
                </Button>
              )
            ) : logedinUser?.subscriptionId ? (
              checkTimeExpired(logedinUser?.subscriptionExpiration) ? (
                <>
                  <p className="expire-message my-0">Your plan has expired!</p>
                  <form
                    action={`${process.env.REACT_APP_BASE_URL}/create-checkout-session`}
                    method="POST"
                  >
                    <input
                      type="hidden"
                      name="priceId"
                      value={plan?.stripePriceKey}
                    />
                    <input type="hidden" name="planId" value={plan?._id} />
                    <Button
                      variant="success"
                      className="btn btn-regular  px-4 mt-3"
                      type="submit"
                    >
                      Purchase Again
                    </Button>
                  </form>
                </>
              ) : (
                <Button
                  variant="warning"
                  className="btn px-4 my-3"
                  onClick={() => handleOpenModal()}
                  disabled={!logedinUser?.subscriptionId}
                >
                  Unsubscribe
                </Button>
              )
            ) : (
              <form
                action={`${process.env.REACT_APP_BASE_URL}/create-checkout-session`}
                method="POST"
              >
                <input
                  type="hidden"
                  name="priceId"
                  value={plan?.stripePriceKey}
                />
                <input type="hidden" name="planId" value={plan?._id} />
                <Button
                  variant="success"
                  className="btn btn-regular  px-4 my-3"
                  type="submit"
                >
                  Purchase Again
                </Button>
              </form>
            )}
            <FiCheckCircle className="active-icon" />
          </>
        ) : parseInt(plan.price) === 0 ? (
          <p className="free-empty"></p>
        ) : logedinUser?.subscriptionId ? (
          <Button
            variant="success"
            className="btn btn-regular  px-4 my-3"
            onClick={() => setBeforePurchaseModal(true)}
          >
            Purchase
          </Button>
        ) : (
          <form
            action={`${process.env.REACT_APP_BASE_URL}/create-checkout-session`}
            method="POST"
          >
            <input type="hidden" name="priceId" value={plan?.stripePriceKey} />
            <input type="hidden" name="planId" value={plan?._id} />
            <Button
              variant="success"
              className="btn btn-regular  px-4 my-3"
              type="submit"
            >
              Purchase
            </Button>
          </form>
        )}
      </div>
      <div className="price-card-details  p-4">
        <p className="price-description">
          <BsFillCheckCircleFill className="price-description-icon" />
          <span>Up to {plan?.numberOfClient} Clients.</span>
        </p>
        <p className="price-description">
          <BsFillCheckCircleFill className="price-description-icon" />
          <span>
            Up to {plan?.numberOfSocialMediaIdea} Social Media Post Generate.
          </span>
        </p>
        <p className="price-description">
          <BsFillCheckCircleFill className="price-description-icon" />
          <span>Up to {plan?.numberOfmealPlan} Meal Plans Generate.</span>
        </p>
      </div>

      <Modal
        scrollable={true}
        show={beforePurchaseModal}
        onHide={() => setBeforePurchaseModal(false)}
        centered
      >
        <SubscriptionInfo
          plan={plan}
          onHide={() => setBeforePurchaseModal(false)}
        />
      </Modal>
      <Modal
        scrollable={true}
        show={openModal}
        onHide={handleCloseModal}
        centered
      >
        <SubscriptionCancleModal handleCloseModal={handleCloseModal} />
      </Modal>
    </Card>
  );
}

export default PriceCard;

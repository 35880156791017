// @flow strict

import * as React from 'react';
import { useState } from 'react';
import { toast } from 'react-toastify';
import { axiosSecure, getAuthorizationHeader } from '../../api/axios';
import SubscriptionHistoryData from '../../component/SubscriptionHistory/SubscriptionHistory';
import { useNavigate, useSearchParams } from 'react-router-dom';

function SubscriptionHistory() {
  const [totalHistory, setTotalHistory] = useState(0);
  const [subscriptions, setSubscriptions] = useState([]);

  const limit = 10;
  const [totalOrderCount, setTotalOrderCount] = useState(0);
  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const currentPage = React.useMemo(() => {
    const page = searchParams.get("page");
    if (page) {
      return +page;
    }
    return 1;
  }, [searchParams])

  const handlePagination = async (page) => {
    navigate(`?page=${page}`);
  };


  React.useEffect(() => {
    const controller = new AbortController();

    const fetchSubscriptionsHistory = async () => {
      try {
        // setDataLoader(true);
        const response = await axiosSecure.get(
          `trainer/orders?page=1&limit=10`,
          {
            headers: { Authorization: getAuthorizationHeader() },
          }
        );
        
        setSubscriptions(response?.data?.data);
        setTotalOrderCount(Number(response?.data.paginationData?.totalPages));
        setTotalHistory(Number(response?.data.paginationData?.totalCount));
      } catch (err) {
        toast.error(err.response.data?.message);
      }
    };
    fetchSubscriptionsHistory();

    return () => controller && controller.abort();
  }, [currentPage]);

  return (
    <div>
      <SubscriptionHistoryData subscriptions={subscriptions} handlePagination={handlePagination} currentPage={currentPage} limit={limit} totalOrderCount={totalOrderCount}/>
    </div>
  );
};

export default SubscriptionHistory;
import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { toast } from "react-toastify";
import { axiosSecure, getAuthorizationHeader } from "../../api/axios";
import PriceCard from "../../component/Pricing/PriceCard";
import { getLogedInUser } from "../Clients/Edit";

function Pricing() {
  const [plans, setPlans] = React.useState([]);
  const logedinUser = getLogedInUser();


  useEffect(() => {
    const controller = new AbortController();
    const fetchClientData = async () => {
      try {
        const response = await axiosSecure.get(`/user-plans`, {
          headers: { Authorization: getAuthorizationHeader() },
        });

        setPlans(response?.data?.data);
      } catch (err) {
        toast.error(err?.response?.data?.message);
      }
    };

    fetchClientData();

    return () => controller && controller.abort();
  }, []);

  return (
    <Container className=" text-center p-5 pricing-page">
      <h3 className="price-section-title">Price Plan</h3>
      <p className="price-subtitle">We offer competitive price</p>
      <Row className="g-4 mt-4">
        {plans.length > 0 &&
          plans.map((plan, index) => (
            <Col key={index} sm={12} md={6} lg={4}>
              <PriceCard
                active={plan._id === logedinUser?.activePlanId}
                plan={plan}
              />
            </Col>
          ))}
      </Row>
    </Container>
  );
}

export default Pricing;

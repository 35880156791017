import React, { useState } from 'react';
import { Field, Formik } from "formik";
import './change-password.scss';
import * as Yup from "yup";
import { useNavigate } from 'react-router-dom';
import { Button, Col, Container, FloatingLabel, Form, FormControl, Row } from 'react-bootstrap';
import { BiArrowBack } from 'react-icons/bi';
import { axiosSecure, getAuthorizationHeader } from '../../api/axios';
import { toast } from 'react-toastify';
const CHANGE_PASSWORD_URL = "change-password"

const validationSchema = Yup.object().shape({
    oldPassword: Yup.string().required('Old Password is required'),
    newPassword: Yup.string()
        .min(6, 'New Password must be at least 6 characters')
        .required('New Password is required'),
    confirmPassword: Yup.string()
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
        .required('Confirm Password is required'),
});

const initialValues = {
    oldPassword: "",
    newPassword: "",
    confirmPassword: "",
};

const ChangePassword = () => {
    const navigate = useNavigate();
    const [showAddToaster, setShowAddToaster] = useState(false);
    const [showErrorToaster, setShowErrorToaster] = useState(false);
    const [error, setError] = useState("");

    return (
        <div className="flex-grow-1">
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting }) => {
                    try {
                        const response = await axiosSecure.put(CHANGE_PASSWORD_URL, values,
                            {
                                headers: { Authorization: getAuthorizationHeader() },
                            }
                        );
                        toast.success(response?.data?.message);
                        navigate(-1);
                    } catch (err) {
                        toast.error(err?.response?.data?.message);
                    } finally {
                        setSubmitting(false);
                    }
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleSubmit,
                    isSubmitting,
                    /* and other goodies */
                }) => (
                    <Form onSubmit={handleSubmit}>
                        <Container className="change-pass d-flex flex-column justify-content-center">
                            <div className="form-title">
                                <h5 className="fw-bold mb-3">CHANGE PASSWORD</h5>

                                <Button
                                    variant="outline-secondary"
                                    size="sm"
                                    className="mb-3"
                                    onClick={() => navigate(-1)}
                                >
                                    <BiArrowBack /> Back
                                </Button>
                            </div>
                            {/* <h5 className="fw-bold mb-3">User Details</h5> */}
                            <Row>
                                <Col md={12} lg={12} xl={12}>
                                    <FloatingLabel
                                        controlId="floatingLastName"
                                        label="Old Password"
                                        className="mb-3"
                                    >
                                        <Field
                                            as={FormControl}
                                            type="password"
                                            name="oldPassword"
                                            placeholder="Old Password"
                                            className={`form-control ${touched.oldPassword && errors.oldPassword ? "is-invalid" : ""
                                                }`}
                                        />
                                        <div className="invalid-feedback">
                                            {touched.oldPassword && errors.oldPassword
                                                ? errors.oldPassword
                                                : null}
                                        </div>
                                    </FloatingLabel>
                                </Col>

                                <Col md={12} lg={12} xl={12}>
                                    <FloatingLabel
                                        controlId="floatingLastName"
                                        label="New Password"
                                        className="mb-3"
                                    >
                                        <Field
                                            as={FormControl}
                                            type="password"
                                            name="newPassword"
                                            placeholder="New Password"
                                            className={`form-control ${touched.newPassword && errors.newPassword ? "is-invalid" : ""
                                                }`}
                                        />
                                        <div className="invalid-feedback">
                                            {touched.newPassword && errors.newPassword
                                                ? errors.newPassword
                                                : null}
                                        </div>
                                    </FloatingLabel>
                                </Col>

                                <Col md={12} lg={12} xl={12}>
                                    <FloatingLabel
                                        controlId="floatingLastName"
                                        label="Confirm Password"
                                        className="mb-3"
                                    >
                                        <Field
                                            as={FormControl}
                                            type="password"
                                            name="confirmPassword"
                                            placeholder="Confirm Password"
                                            className={`form-control ${touched.confirmPassword && errors.confirmPassword ? "is-invalid" : ""
                                                }`}
                                        />
                                        <div className="invalid-feedback">
                                            {touched.confirmPassword && errors.confirmPassword
                                                ? errors.confirmPassword
                                                : null}
                                        </div>
                                    </FloatingLabel>
                                </Col>
                            </Row>
                            <Row></Row>
                            <Col md={12} lg={12} xl={12} className="mt-4 mb-4 ">
                                <Button type="submit" disabled={isSubmitting}>
                                    UPDATE PASSWORD
                                </Button>
                            </Col>
                        </Container>
                    </Form>
                )}
            </Formik>
        </div>
    )
}

export default ChangePassword;

import React, { useEffect, useMemo, useState } from "react";
import {
  Button,
  ButtonGroup,
  Card,
  Col,
  Container,
  OverlayTrigger,
  Row,
  Tooltip,
} from "react-bootstrap";
import Table from "react-bootstrap/Table";
import { MdNote } from "react-icons/md";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import { convertDate } from "../../../Utility/utility";
import { axiosSecure, getAuthorizationHeader } from "../../../api/axios";
import DeleteModal from "../../../component/Modal";
import PaginationComponent from "../../../component/Pagination/Pagination";
import "./listClient.scss";

const ListClient = () => {
  const limit = 10;
  const [dataLoader, setDataLoader] = useState(false);
  const [clientList, setClientList] = useState(null);
  const [totalClientCount, setTotalClientCount] = useState(0);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemToDelete, setItemToDelete] = useState(null);

  let [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const currentPage = useMemo(() => {
    const page = searchParams.get("page");
    if (page) {
      return +page;
    }
    return 1;
  }, [searchParams]);

  const handleDelete = async () => {
    if (itemToDelete) {
      const requestPayload = {
        _id: itemToDelete._id,
      };
      try {
        const response = await axiosSecure.post(
          "/trainer/client/delete",
          requestPayload,
          {
            headers: { Authorization: getAuthorizationHeader() },
          }
        );

        toast.success(response.data.message);

        setClientList((prevState) => {
          const filteredList = prevState.filter(
            (client) => client._id !== itemToDelete._id
          );
          return filteredList;
        });
        setTotalClientCount(totalClientCount - 1);
        navigate(`?page=1`);
      } catch (err) {
        toast.error(err.response.data.message);
      } finally {
        setItemToDelete(null);
      }
    }
    setShowDeleteModal(false);
  };

  const handleDeleteClick = (item) => {
    setShowDeleteModal(true);
    setItemToDelete(item);
  };

  const handlePagination = async (page) => {
    navigate(`?page=${page}`);
  };

  useEffect(() => {
    const fetchClientListAsNeed = async () => {
      try {
        setDataLoader(true);
        const response = await axiosSecure.get(
          `/trainer/clients?page=${currentPage}&limit=${limit}`,
          {
            headers: { Authorization: getAuthorizationHeader() },
          }
        );
        setClientList(response?.data?.data);
        setTotalClientCount(Number(response?.data.paginationData?.totalCount));
      } catch (err) {
        toast.error(err?.response?.data?.message);
      } finally {
        setDataLoader(false);
      }
    };
    fetchClientListAsNeed();
  }, [currentPage, limit]);

  if (dataLoader) {
    return (
      <Container className="mt-4">
        <Row>
          <Col>
            <div className="user_card_wrapper m-auto">
              <div className="d-flex justify-content-center align-items-center h-100">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  return (
    <>
      <Container className="flex-grow-1 mt-2">
        <div className="bg-white p-3 mt-4 rounded-2">
          <div className="row align-items-centner pt-4 pb-3">
            <div className="col-lg-7 col-md-7 col-sm-7 col-7">
              <h2>Client Listing</h2>
            </div>
            <div className="col-lg-5 col-md-5 col-sm-5 col-5">
              <div className="d-flex justify-content-end">
                <Link to="/client/add" replace className="btn btn-primary">
                  Add Client
                </Link>
              </div>
            </div>
          </div>

          {clientList && clientList?.length > 0 ? (
            <div className="user-table">
              <Table striped hover responsive>
                <thead>
                  <tr>
                    <th>Full Name</th>
                    <th>Email</th>
                    <th>Age</th>
                    <th>Generated Meals</th>
                    <th>Saved Meals</th>
                    <th>Created At</th>
                    <th className="text-center">Action</th>
                  </tr>
                </thead>
                <tbody className="table-group-divider">
                  {clientList.map((item, index) => (
                    <tr key={index}>
                      <td>
                        {item.firstName} {item.lastName}
                      </td>
                      <td>{item.email}</td>
                      <td>{item.age}</td>
                      <td>{item?.generatedMeals}</td>
                      <td>{item?.savedPlansCount}</td>
                      {/* <td>0</td> */}
                      <td> {convertDate(item.createdAt)}</td>
                      <td className="text-center">
                        <ButtonGroup
                          aria-label="Basic example"
                          className="buttonGroup"
                        >
                          <OverlayTrigger
                            overlay={<Tooltip>Edit Client Details</Tooltip>}
                          >
                            <Button
                              size="sm"
                              variant="light"
                              onClick={() =>
                                navigate(`/client/edit/${item?._id}`)
                              }
                            >
                              <i className="bi bi-pencil-square"></i>
                            </Button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            overlay={<Tooltip>View Client Details</Tooltip>}
                          >
                            <Button
                              size="sm"
                              variant="light"
                              onClick={() =>
                                navigate(`/client/view/${item?._id}`)
                              }
                            >
                              <i className="bi bi-eye"></i>
                            </Button>
                          </OverlayTrigger>
                          <OverlayTrigger
                            overlay={<Tooltip>Delete Client.</Tooltip>}
                          >
                            <Button
                              size="sm"
                              variant="light"
                              onClick={() => handleDeleteClick(item)}
                            >
                              <i className="bi bi-trash"></i>
                            </Button>
                          </OverlayTrigger>

                          <OverlayTrigger
                            overlay={<Tooltip>View Saved Meal Plans.</Tooltip>}
                          >
                            <Button
                              size="sm"
                              variant="light"
                              onClick={() =>
                                navigate(`/saved-meals/${item?._id}`)
                              }
                            >
                              <MdNote />
                            </Button>
                          </OverlayTrigger>
                        </ButtonGroup>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>

              {/* Delete Confirm Modal */}
              <DeleteModal
                show={showDeleteModal}
                onHide={() => setShowDeleteModal(false)}
                onDelete={handleDelete}
                itemName={`Are you sure delete this client?`}
              />
            </div>
          ) : (
            <Row>
              <Col>
                <Table>
                  <thead>
                    <tr>
                      <th>Full Name</th>
                      <th>Email</th>
                      <th>Age</th>
                      <th>Generated Meals</th>
                      <th>Saved Meals</th>
                      <th>Created At</th>
                      <th className="text-center">Action</th>
                    </tr>
                  </thead>
                  <tbody></tbody>
                </Table>
                <Card>
                  <Card.Body>
                    <Card.Title className="text-danger text-center">
                      No client data available
                    </Card.Title>
                  </Card.Body>
                </Card>
              </Col>
            </Row>
          )}
          <div className="d-flex justify-content-end relative bottom-20 me-3 mt-3 ">
            <PaginationComponent
              total={totalClientCount}
              itemsPerPage={limit}
              currentPage={currentPage}
              onPageChange={(page) => handlePagination(page)}
            />
          </div>
        </div>
      </Container>
    </>
  );
};

export default ListClient;

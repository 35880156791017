import React, { useEffect, useState } from "react";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { axiosSecure, getAuthorizationHeader } from "../../../api/axios";
import "./Edit.scss";
import EditClientForm from "./client-edit-form";
export const getLogedInUser = () =>
  localStorage.getItem("userDetails")
    ? JSON.parse(localStorage.getItem("userDetails")).user
    : null;

const EditClient = () => {
  const { clientId } = useParams();
  const [initialValues, setInitialValues] = useState(null);
  const [loading, setLoading] = useState(false);
  const logedinUser = getLogedInUser();
  // GET Client
  useEffect(() => {
    const controller = new AbortController();
    const fetchClientData = async () => {
      setLoading(true);
      try {
        const response = await axiosSecure.get(`/trainer/client/${clientId}`, {
          headers: { Authorization: getAuthorizationHeader() },
        });
        const height = (response?.data?.data?.height?.match(/\d+/g));
        const feet  = height[0];
        const inches = height[1];        
        const weight = Number(response?.data?.data?.weight?.split(" ")[0]);
        const gender = response?.data?.data?.gender?.toString();
        setInitialValues({ ...response?.data?.data, feet, inches, weight, gender });
      } catch (err) {
        toast.error(err?.response?.data?.message);
      } finally {
        setLoading(false);
      }
    };
    fetchClientData();

    return () => controller && controller.abort();
  }, [clientId]);

  if (loading) {
    return (
      <Container>
        <Row>
          <Col>
            <div className="user_card_wrapper m-auto">
              <div className="d-flex justify-content-center align-items-center h-100">
                <div className="spinner-border" role="status">
                  <span className="visually-hidden">Loading...</span>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  return initialValues && logedinUser ? (
    <EditClientForm defaultValue={initialValues} trainer={logedinUser} />
  ) : (
    <></>
  );
};

export default EditClient;

import { useEffect, useState } from 'react';
import { toast } from 'react-toastify';
import { axiosSecure, getAuthorizationHeader } from '../api/axios';


const useTrainerPlan = () => {
  const [remaining, setRemaining] = useState(null);

  useEffect(() => {
    const fetchRemainingPlan = async () => {
      try {
        const response = await axiosSecure.get("/trainer/remaining", {
          headers: { Authorization: getAuthorizationHeader() },
        });
        setRemaining(response?.data?.data);
      } catch (err) {
        toast.error(err?.response?.data?.message);
      }
    };
    fetchRemainingPlan();
  }, []);

  return { remaining };
};

export default useTrainerPlan;

import { ErrorMessage, Field, Formik } from "formik";
import React, { useState } from "react";
import { FormCheck, FormControl } from "react-bootstrap";
import Button from "react-bootstrap/Button";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import FloatingLabel from "react-bootstrap/FloatingLabel";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import { BiArrowBack } from "react-icons/bi";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import * as yup from "yup";
import { axiosSecure, getAuthorizationHeader } from "../../../api/axios";
import { Toaster } from "../../../component/Toaster/Toaster";
import { getLogedInUser } from "../Edit";
import "./client.scss";

// input field validation schema
const schema = yup.object().shape({
  firstName: yup.string().required("First name is required"),
  lastName: yup.string().required("Last name is required"),
  email: yup
    .string()
    .email("Invalid email address")
    .required("Email is required"),
  age: yup
    .number()
    .positive("Age must be a positive number")
    .required("Age is required"),
  feet: yup
    .number()
    .positive("Feet must be a positive number")
    .required("Feet is required"),
  inches: yup
    .number()
    .required("Inches is required"),
  weight: yup
    .number()
    .positive("Weight must be a positive number")
    .required("Weight is required"),
  gender: yup.number().required("Gender is required"),
  // goal: yup.array().min(1, 'Goal is required').required(),
  goal: yup.string().required("Goal is required"),
  foodDontLike: yup.string().required("Food Options Not Preferred is required"),
  preferenceFood: yup.string().required("Preferred Food is required"),
  allergies: yup.string().required("Allergies are required"),

  // schedule: yup.string().required("Meal type is required"),
  activity: yup.string().required('Activity Level is required'),
});

const initialValues = {
  firstName: "",
  lastName: "",
  email: "",
  age: "",
  height: "",
  weight: "",
  gender: 1,
  goal: "",
  foodDontLike: "",
  preferenceFood: "",
  allergies: "",
  feet: "5",
  inches: "0",
  activity: ""
};

const AddClient = () => {
  const navigate = useNavigate();
  const [showAddToaster, setShowAddToaster] = useState(false);
  const [showErrorToaster, setShowErrorToaster] = useState(false);
  const [error, setError] = useState("");
  const logedinUser = getLogedInUser();

  const handleOnSubmit = async (values, { setSubmitting }) => {

    const payload = JSON.stringify({
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      age: values.age,
      height: `${values.feet}'${values.inches}"`,
      weight: `${values.weight} lbs`,
      goal: values.goal.toString(),
      gender: Number(values.gender),
      foodDontLike: values.foodDontLike,
      preferenceFood: values.preferenceFood,
      allergies: values.allergies,
      trainerId: logedinUser._id,
      activity: values.activity
    })

    try {
      const response = await axiosSecure.post(
        "/trainer/client/add",
        payload,
        {
          headers: { Authorization: getAuthorizationHeader() },
        }
      );
      toast.success(response?.data?.message);
      navigate("/client", { replace: true });
    } catch (err) {
      toast.error(err?.response?.data?.message);
    } finally {
      setSubmitting(false);
    }
  }

  return (
    <div className="flex-grow-1">
      <Formik
        initialValues={initialValues}
        validationSchema={schema}
        onSubmit={handleOnSubmit}
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
          isSubmitting,
          /* and other goodies */
        }) => (
          <Form onSubmit={handleSubmit}>
            <Container className="add-user-page d-flex flex-column justify-content-center">
              <h2 className="mb-4">Add Client</h2>
              <div className="form-title">
                <h5 className="fw-bold mb-3">Client Details</h5>

                <Button
                  variant="outline-secondary"
                  size="sm"
                  onClick={() => navigate(-1)}
                  className="mb-3"
                >
                  <BiArrowBack /> Back
                </Button>
              </div>
              <Row className="gy-2">
                <Col md={6} lg={6} xl={6}>
                  <FloatingLabel
                    controlId="firstName"
                    label="First Name"
                    className="mb-3"
                  >
                    <Field
                      as={FormControl}
                      type="text"
                      name="firstName"
                      placeholder="First Name"
                      className={`form-control ${touched.firstName && errors.firstName
                        ? "is-invalid"
                        : ""
                        }`}
                    />
                    <div className="invalid-feedback">
                      {touched.firstName && errors.firstName
                        ? errors.firstName
                        : null}
                    </div>
                  </FloatingLabel>
                </Col>

                <Col md={6} lg={6} xl={6}>
                  <FloatingLabel
                    controlId="lastName"
                    label="Last Name"
                    className="mb-3"
                  >
                    <Field
                      as={FormControl}
                      type="text"
                      name="lastName"
                      placeholder="Last Name"
                      className={`form-control ${touched.lastName && errors.lastName ? "is-invalid" : ""
                        }`}
                    />
                    <div className="invalid-feedback">
                      {touched.lastName && errors.lastName
                        ? errors.lastName
                        : null}
                    </div>
                  </FloatingLabel>
                </Col>

                <Col md={6} lg={6} xl={6}>
                  <FloatingLabel
                    controlId="email"
                    label="Email"
                    className="mb-3"
                  >
                    <Field
                      as={FormControl}
                      type="email"
                      name="email"
                      placeholder="Email"
                      className={`form-control ${touched.email && errors.email ? "is-invalid" : ""
                        }`}
                    />
                    <div className="invalid-feedback">
                      {touched.email && errors.email ? errors.email : null}
                    </div>
                  </FloatingLabel>
                </Col>

                <Col md={6} lg={6} xl={6}>
                  <FloatingLabel controlId="age" label="Age" className="mb-3">
                    <Field
                      as={FormControl}
                      type="number"
                      name="age"
                      placeholder="Age"
                      className={`form-control ${touched.age && errors.age ? "is-invalid" : ""
                        }`}
                    />
                    <div className="invalid-feedback">
                      {touched.age && errors.age ? errors.age : null}
                    </div>
                  </FloatingLabel>
                </Col>
                <p className="form-input-title">Height:</p>
                <Col md={6} lg={6} xl={6} >
                  <div className="d-flex align-items-start gap-3 w-100">
                    <FloatingLabel
                      controlId="feet"
                      label="Feet"
                      className="mb-3 w-50"
                    >
                      <Field
                        as={FormControl}
                        type="number"
                        name="feet"
                        placeholder="feet"
                        className={`form-control ${touched.feet && errors.feet ? "is-invalid" : ""
                          }`}
                      />
                      <div className="invalid-feedback">
                        {touched.feet && errors.feet ? errors.feet : null}
                      </div>
                    </FloatingLabel>
                    <FloatingLabel
                      controlId="height"
                      label="Inches"
                      className="mb-3 w-50"
                    >
                      <Field
                        as={FormControl}
                        type="number"
                        name="inches"
                        placeholder="Inches"
                        className={`form-control ${touched.inches && errors.inches ? "is-invalid" : ""
                          }`}
                      />
                      <div className="invalid-feedback">
                        {touched.inches && errors.inches ? errors.inches : null}
                      </div>
                    </FloatingLabel>
                  </div>
                </Col>

                <Col md={6} lg={6} xl={6}>
                  <FloatingLabel
                    controlId="weight"
                    label="Weight in Pound(lbs)"
                    className="mb-3"
                  >
                    <Field
                      as={FormControl}
                      type="text"
                      name="weight"
                      placeholder="Weight in Pound"
                      className={`form-control ${touched.weight && errors.weight ? "is-invalid" : ""
                        }`}
                    />
                    <div className="invalid-feedback">
                      {touched.weight && errors.weight ? errors.weight : null}
                    </div>
                  </FloatingLabel>
                </Col>

                <Col md={6} lg={6} xl={6}>
                  <FloatingLabel
                    controlId="foodDontLike"
                    label="Food Options Not Preferred (eg., red meat)"
                    className="mb-3"
                  >
                    <Field
                      as={FormControl}
                      type="text"
                      name="foodDontLike"
                      placeholder="Food Options Not Preferred (eg., red meat)"
                      className={`form-control ${touched.foodDontLike && errors.foodDontLike
                        ? "is-invalid"
                        : ""
                        }`}
                    />
                    <div className="invalid-feedback">
                      {touched.foodDontLike && errors.foodDontLike
                        ? errors.foodDontLike
                        : null}
                    </div>
                  </FloatingLabel>
                </Col>
                <Col md={6} lg={6} xl={6}>
                  <FloatingLabel
                    controlId="preferenceFood"
                    label="Preferred Food"
                    className="mb-3"
                  >
                    <Field
                      as={FormControl}
                      type="text"
                      name="preferenceFood"
                      placeholder="Food Dont Like"
                      className={`form-control ${touched.preferenceFood && errors.preferenceFood
                        ? "is-invalid"
                        : ""
                        }`}
                    />
                    <div className="invalid-feedback">
                      {touched.preferenceFood && errors.preferenceFood
                        ? errors.preferenceFood
                        : null}
                    </div>
                  </FloatingLabel>
                </Col>

                <Col md={6} lg={6} xl={6}>
                  <FloatingLabel
                    controlId="allergies"
                    label="Allergies"
                    className="mb-3"
                  >
                    <Field
                      as={FormControl}
                      type="text"
                      name="allergies"
                      placeholder="Allergies"
                      className={`form-control ${touched.allergies && errors.allergies
                        ? "is-invalid"
                        : ""
                        }`}
                    />
                    <div className="invalid-feedback">
                      {touched.allergies && errors.allergies
                        ? errors.allergies
                        : null}
                    </div>
                  </FloatingLabel>
                </Col>

                <Col md={6} lg={6} xl={6}>
                  <Form.Group controlId="activity">
                    <Field
                      as="select"
                      name="activity"
                      style={{ height: "57px" }}
                      className={`form-control ${touched.activity && errors.activity ? "is-invalid" : ""
                        }`}
                    >
                      <option value="">Activity Level</option>
                      <option value="Sedentary">Sedentary</option>
                      <option value="Light Exercise (1-2 days/week)">Light Exercise (1-2 days/week)</option>
                      <option value="Moderate (3-5 days/week)">Moderate (3-5 days/week)</option>
                      <option value="Heavy (6-7 days/week)">Heavy (6-7 days/week)</option>
                    </Field>
                    <ErrorMessage
                      name="activity"
                      component="div"
                      className="invalid-feedback"
                    />
                  </Form.Group>
                </Col>

                <Col md={6} lg={6} xl={6}>
                  <Form.Group controlId="gender">
                    <Form.Label>
                      <b>Gender</b>
                    </Form.Label>
                    <div className="gender">
                      <div style={{ marginRight: "2rem" }}>
                        <Field
                          type="radio"
                          name="gender"
                          value="1"
                          id="gender-male"
                          className={`form-check-input ${touched.gender && errors.gender ? "is-invalid" : ""
                            }`}
                        />
                        <FormCheck.Label htmlFor="gender-male">
                          Male
                        </FormCheck.Label>
                      </div>
                      <div style={{ marginRight: "2rem" }}>
                        <Field
                          type="radio"
                          name="gender"
                          value="2"
                          id="gender-female"
                          className={`form-check-input ${touched.gender && errors.gender ? "is-invalid" : ""
                            }`}
                        />
                        <FormCheck.Label htmlFor="gender-female">
                          Female
                        </FormCheck.Label>
                      </div>
                      <div>
                        <Field
                          type="radio"
                          name="gender"
                          value="3"
                          id="gender-other"
                          className={`form-check-input ${touched.gender && errors.gender ? "is-invalid" : ""
                            }`}
                        />
                        <FormCheck.Label htmlFor="gender-other">
                          Other
                        </FormCheck.Label>
                      </div>
                    </div>
                  </Form.Group>
                </Col>

                <Col md={6} lg={6} xl={6}>
                  <Form.Group controlId="goal">
                    <Form.Label>
                      <b>Goal</b>
                    </Form.Label>
                    <div className="gender">
                      <div style={{ marginRight: "2rem" }}>
                        <Field
                          type="radio"
                          name="goal"
                          value="lose-weight"
                          id="lose-weight"
                          className={`form-check-input ${touched.goal && errors.goal ? "is-invalid" : ""
                            }`}
                        />
                        <FormCheck.Label htmlFor="lose-weight">
                          Lose Weight
                        </FormCheck.Label>
                      </div>
                      <div style={{ marginRight: "2rem" }}>
                        <Field
                          type="radio"
                          name="goal"
                          value="maintain-weight"
                          id="maintain-weight"
                          className={`form-check-input ${touched.goal && errors.goal ? "is-invalid" : ""
                            }`}
                        />
                        <FormCheck.Label htmlFor="maintain-weight">
                          Maintain Weight
                        </FormCheck.Label>
                      </div>
                      <div>
                        <Field
                          type="radio"
                          name="goal"
                          value="gain-weight"
                          id="gain-weight"
                          className={`form-check-input ${touched.goal && errors.goal ? "is-invalid" : ""
                            }`}
                        />
                        <FormCheck.Label htmlFor="gain-weight">
                          Gain Weight
                        </FormCheck.Label>
                      </div>
                    </div>
                  </Form.Group>
                </Col>
              </Row>
              <Row></Row>
              <Col md={12} lg={12} xl={12} className="mt-4 mb-4 ">
                <Button type="submit" disabled={isSubmitting}>
                  CREATE CLIENT
                </Button>
              </Col>
            </Container>
          </Form>
        )}
      </Formik>
      <Toaster
        title="Client added successfully"
        bg="success"
        showToaster={showAddToaster}
        setShowToaster={setShowAddToaster}
        to="client"
      ></Toaster>
      <Toaster
        title={error}
        bg="danger"
        showToaster={showErrorToaster}
        setShowToaster={setShowErrorToaster}
        to="client/add"
      ></Toaster>
    </div>
  );
};

export default AddClient;

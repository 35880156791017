// @flow strict

import * as React from "react";
import { BiHistory, BiHomeCircle } from "react-icons/bi";
import { BsFillFileEarmarkPostFill } from "react-icons/bs";
import { GiMeal } from "react-icons/gi";
import { IoMdPricetags } from "react-icons/io";
import { MdDashboard, MdSchedule, MdSubscriptions } from "react-icons/md";
import { NavLink } from "react-router-dom";
import { useWindowSize } from "react-use";
import { SidebarContext } from "../../../contexts/SidebarContext";

function SidebarNav({ navigationItem }) {
  const { activeMenu, setActiveMenu } = React.useContext(SidebarContext);
  const { width } = useWindowSize();
  return (
    <>
      <nav className="h-100vh">
        <ul className="nav nav-pills flex-column mb-auto">
          <li className="nav-item">
            <NavLink
              end={true}
              to="/"
              className={({ isActive }) =>
                `nav-link ${isActive ? "active" : "dark-green"}`
              }
              onClick={() => (width < 576 ? setActiveMenu(false) : {})}
            >
              <BiHomeCircle className="bi bi-house-door pe-none me-2" />
              <span>Dashboard</span>
            </NavLink>
          </li>

          {navigationItem === "super-admin" ? (
            <SuperAdminNavs />
          ) : (
            <TranerNavs />
          )}
        </ul>
      </nav>
    </>
  );
}

export default SidebarNav;

const TranerNavs = () => {
  const { activeMenu, setActiveMenu } = React.useContext(SidebarContext);
  const { width } = useWindowSize();

  return (
    <>
      <li>
        <NavLink
          to="/client?page=1"
          className={({ isActive }) =>
            `nav-link ${isActive ? "active" : "dark-green"}`
          }
          onClick={() => (width < 576 ? setActiveMenu(false) : {})}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-people pe-none me-2"
            viewBox="0 0 16 16"
          >
            <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
          </svg>
          <span>Client</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/socialmedia-idea"
          className={({ isActive }) =>
            `nav-link ${isActive ? "active" : "dark-green"}`
          }
          onClick={() => (width < 576 ? setActiveMenu(false) : {})}
        >
          <MdDashboard className="bi bi-people pe-none me-2" />
          <span>Social Media Post</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/saved-posts"
          className={({ isActive }) =>
            `nav-link ${isActive ? "active" : "dark-green"}`
          }
          onClick={() => (width < 576 ? setActiveMenu(false) : {})}
        >
          <BsFillFileEarmarkPostFill className="bi bi-people pe-none me-2" />
          <span>Saved Posts</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/meal-planing"
          className={({ isActive }) =>
            `nav-link ${isActive ? "active" : "dark-green"}`
          }
          onClick={() => (width < 576 ? setActiveMenu(false) : {})}
        >
          <GiMeal className="bi bi-people pe-none me-2" />
          <span>Meal Planning</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/pricing"
          className={({ isActive }) =>
            `nav-link ${isActive ? "active" : "dark-green"}`
          }
          onClick={() => (width < 576 ? setActiveMenu(false) : {})}
        >
          <IoMdPricetags className="me-2" />
          <span>Pricing Plans</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/subscription-history"
          className={({ isActive }) =>
            `nav-link ${isActive ? "active" : "dark-green"}`
          }
          onClick={() => (width < 576 ? setActiveMenu(false) : {})}
        >
          <MdSubscriptions className="me-2" />
          <span>Subscription History</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/schedule-meal-plan"
          className={({ isActive }) =>
            `nav-link ${isActive ? "active" : "dark-green"}`
          }
          onClick={() => (width < 576 ? setActiveMenu(false) : {})}
        >
          <MdSchedule className="me-2" />
          <span>Schedule Meal Plan</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/schedule-meal-plan-history"
          className={({ isActive }) =>
            `nav-link ${isActive ? "active" : "dark-green"}`
          }
          onClick={() => (width < 576 ? setActiveMenu(false) : {})}
        >
          <BiHistory className="me-2" />
          <span>Schedule Meal Plan History</span>
        </NavLink>
      </li>
    </>
  );
};

const SuperAdminNavs = () => {
  const { activeMenu, setActiveMenu } = React.useContext(SidebarContext);
  const { width } = useWindowSize();
  return (
    <>
      <li>
        <NavLink
          to="/user"
          className={({ isActive }) =>
            `nav-link ${isActive ? "active" : "dark-green"}`
          }
          onClick={() => (width < 576 ? setActiveMenu(false) : {})}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-people pe-none me-2"
            viewBox="0 0 16 16"
          >
            <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
          </svg>
          <span>Trainer</span>
        </NavLink>
      </li>
      <li>
        <NavLink
          to="/user-plan"
          className={({ isActive }) =>
            `nav-link ${isActive ? "active" : "dark-green"}`
          }
          onClick={() => (width < 576 ? setActiveMenu(false) : {})}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            className="bi bi-people pe-none me-2"
            viewBox="0 0 16 16"
          >
            <path d="M15 14s1 0 1-1-1-4-5-4-5 3-5 4 1 1 1 1h8zm-7.978-1A.261.261 0 0 1 7 12.996c.001-.264.167-1.03.76-1.72C8.312 10.629 9.282 10 11 10c1.717 0 2.687.63 3.24 1.276.593.69.758 1.457.76 1.72l-.008.002a.274.274 0 0 1-.014.002H7.022zM11 7a2 2 0 1 0 0-4 2 2 0 0 0 0 4zm3-2a3 3 0 1 1-6 0 3 3 0 0 1 6 0zM6.936 9.28a5.88 5.88 0 0 0-1.23-.247A7.35 7.35 0 0 0 5 9c-4 0-5 3-5 4 0 .667.333 1 1 1h4.216A2.238 2.238 0 0 1 5 13c0-1.01.377-2.042 1.09-2.904.243-.294.526-.569.846-.816zM4.92 10A5.493 5.493 0 0 0 4 13H1c0-.26.164-1.03.76-1.724.545-.636 1.492-1.256 3.16-1.275zM1.5 5.5a3 3 0 1 1 6 0 3 3 0 0 1-6 0zm3-2a2 2 0 1 0 0 4 2 2 0 0 0 0-4z" />
          </svg>
          <span>User Plan</span>
        </NavLink>
      </li>
    </>
  );
};

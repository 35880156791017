import React from "react";
import { Alert, Button, Modal } from "react-bootstrap";
import { logout } from "../../service";

const ExpiredModal = ({ show, setShow }) => {
  const handleClose = (e) => {
    logout();
    setShow(false);
  };

  return (
    <Modal centered show={show}>
      <Modal.Body className="p-0">
        <Alert variant='warning' className="p-4">
          <h3>Login Session Expired!</h3>
          <p className="py-2">
            Your login session has expired due to inactivity. Please log in again to continue.
          </p>
          <div className="d-flex justify-content-end">
            <Button className="px-3" variant="warning" onClick={handleClose}>
              Login again
            </Button>
          </div>
        </Alert>
      </Modal.Body>
    </Modal>
  );
};

export default ExpiredModal;
